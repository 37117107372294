import { Injectable } from '@angular/core';
import { ApplicationGlobalsService, UserService, UrlService } from 'app-services/services.module';
import 'rxjs/add/operator/toPromise';
import { LocalizationService } from './localization.service';
import { UserLanguage } from 'app-models/user/user.module';
import moment from 'moment';
import { getHttpRequestOptions } from 'app-scripts/utilities/general';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ApplicationLoadService {

    constructor(
        private applicationGlobalsService: ApplicationGlobalsService,
        private userService: UserService,
        private localizationService: LocalizationService,
        private urlService: UrlService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
    ) { }

    initApplication(): any {
        this.applicationGlobalsService.setGlobalConfigurations();
        this.setLocalizationLanguage();
        this.applicationGlobalsService.setGlobalConfigurations();
        if (window.document.URL.toString().contains('login')) {
            if (!this.urlService.hasParams()) {
                const authUrl = this.applicationGlobalsService.globalConfigurations.authUrl;
                window.location.href = authUrl + encodeURIComponent(this.applicationGlobalsService.globalConfigurations.mainUrl);
            }
            else {
                return;
            }
        }
        else {
            const url = this.urlService.getRequestUrl('users', 'me') + `?timeStamp=${moment().unix()}`;
            const promise = this.httpClient.get(url, getHttpRequestOptions())
                .toPromise()
                .then(async (userResult: any) => {
                    this.userService.set({
                        permissions: userResult.payload.userObj.permissions,
                        name: `${userResult.payload.userObj.first_name} ${userResult.payload.userObj.last_name}`,
                        language: UserLanguage.English,
                        role: userResult.payload.userObj.role,
                    });
                    this.setLocalizationLanguage();
                },
                    (err) => {
                        const currentUrl = window.location.href;
                        const authUrl = this.applicationGlobalsService.globalConfigurations.authUrl;
                        window.location.href = authUrl + encodeURIComponent(currentUrl);
                    });

            return promise;
        }
    }

    setLocalizationLanguage = (): void => {
        const localizationFile = this.localizationService.localizationFile;
        if (this.userService.language !== UserLanguage.English) {
            const baseLocalizationFile = this.localizationService.baseLocalizationFile;
            this.applicationGlobalsService.baseLocalization = baseLocalizationFile;
        }
        this.applicationGlobalsService.localization = localizationFile;
    }
}
