import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'unauthorized-page',
  templateUrl: './unauthorized.view.html',
  styleUrls: ['./unauthorized.page.scss']
})

export class UnauthorizedPageComponent {
  constructor() {}
}
