import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { TranslationService } from 'app-services/translation.service';
import { PermissionsTableConfiguration } from './configurations/permissions-table.configuration';
import { PermissionsPageService } from './permissions.service';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastMessagesConfiguration } from 'app-configurations/toast-messages.cofiguration';
import { Text } from 'app-models/common/text.model';
import { MessageService } from 'primeng/api';
import { PermissionsTableColumnsConfiguration } from './configurations/permissions-table-columns.configuration';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { AppDTO } from 'app-models/app/app-dto.model';
import { Severity } from 'app-models/toast/severity.enum';
import { sortBy } from 'lodash';

@Component({
  selector: 'app-permissions-page',
  templateUrl: './permissions.page.html',
  styleUrls: ['./permissions.page.scss']
})
export class PermissionsPageComponent extends ComponentWithSubscriptions implements OnInit, OnDestroy {

  permissionsTable: any;
  apps: AppDTO[];
  newPermissionForm: FormGroup;

  constructor(
    private permissionsService: PermissionsPageService,
    private translation: TranslationService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.permissionsTable = {
      config: PermissionsTableConfiguration,
      columns: deepCloneObject(PermissionsTableColumnsConfiguration),
    };
    this.subscriptions.push(this.permissionsService.getAppsPermissions().subscribe(result => {
      this.apps = result.appsMap;
      result.permissions.forEach((permission) => {
        permission.name = permission.name.toDisplayText();
      });
      this.permissionsTable.data = result.permissions;
      this.apps.forEach((app) => {
        this.permissionsTable.columns.push({
          header: `${app.name}`,
          field: `apps.${app.name}`,
          types: [TableCellType.Checkbox],
          width: '10px',
        });
      });
      this.permissionsTable.config = PermissionsTableConfiguration;
    }));

    this.newPermissionForm = new FormGroup({
      name: new FormControl('', Validators.required)
    });
  }


  notificationReceived(notification: Notification): void {
    switch (notification.type) {
      case NotificationTypeEnum.ItemClicked:
        const data = notification.data;
        const field = data.field.startsWith('apps.') ? data.field.replace('apps.', '') : data.field;
        const status = data.row.apps ? data.row.apps[field] : data.row.field;
        const app = this.apps.find((application) => application.name === field);
        this.subscriptions.push(this.permissionsService.updatePermission(
          { appId: app.id, permissionId: data.row.id }, status).subscribe((res) => {
            if (!res) {
              this.addToast(ToastMessagesConfiguration.ADD_PERM_ERROR, Severity.Error);
            }
            if (status) {
              this.addToast(ToastMessagesConfiguration.PERMISSION_ADDED, Severity.Success);
            } else {
              this.addToast(ToastMessagesConfiguration.PERMISSOIN_REMOVED, Severity.Success);
            }
          }));
        break;
    }
  }

  createPermission = () => {
    const displayName = this.newPermissionForm.value.name;
    const permissionKey = displayName.toSnakeCase();
    this.subscriptions.push(this.permissionsService.createPermission(permissionKey)
      .subscribe((newPermissionObj) => {
        if (!newPermissionObj.success) {
          this.addToast(new Text(newPermissionObj.message, false), Severity.Error);
        } else {
          this.addToast(ToastMessagesConfiguration.PERMISSION_CREATED, Severity.Success);
          const permissionApps: any = [];
          this.apps.forEach((app) => {
            permissionApps[app.name] = false;
          });
          const newPermission = {
            name: newPermissionObj.permission.name.toDisplayText(),
            id: newPermissionObj.permission.id,
            apps: permissionApps
          };
          this.permissionsTable.data.push(newPermission);
          this.permissionsTable.data = sortBy(this.permissionsTable.data, ['name']);
          this.newPermissionForm.reset();
        }
      }
      ));
  }

  addToast(message: Text, severity: string) {
    const detail = message.needsTranslation ? this.translation.translate(message.text) : message.text;
    this.messageService.add({
      severity,
      summary: this.translation.translate(severity),
      detail,
      key: 'toastMessage',
    });
  }
}
