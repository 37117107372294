import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { UserActionsConfigItem } from 'app-models/actions/user-actions-config-item.model';
import { Text } from 'app-models/common/text.model';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { UserActionsConfiguration } from './configurations/user-actions.configuration';

@Component({
  selector: 'app-user-action',
  templateUrl: './user-action.component.html',
  styleUrls: ['./user-action.component.scss']
})
export class UserActionComponent implements OnChanges, OnInit {

  readonly config = deepCloneObject(UserActionsConfiguration);
  @Input() action: UserActionType;
  @Input() tooltip: Text;
  @Input() tooltipPosition: string;
  @Output() notify: EventEmitter<Notification> = new EventEmitter();
  configItem: UserActionsConfigItem;
  UserActionType = UserActionType;

  constructor() { }

  ngOnInit() {
    this.tooltipPosition = this.tooltipPosition ? this.tooltipPosition : 'bottom';
  }

  ngOnChanges(): void {
    this.configItem = this.config[this.action];
    if (!this.configItem) { return; }
    if (this.tooltip) {
      this.configItem.icon.tooltip = this.tooltip;
    }
  }

  onClick = (): void => {
    this.notify.emit({
      type: NotificationTypeEnum.ItemClicked,
      data: this.action
    });
  }
}
