import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/index';
import { User } from '../models/user/user.model';
import { UserDTO } from 'app-models/user/user-dto.model';
import { AppDTO } from 'app-models/app/app-dto.model';

@Injectable()
export class ApplicationGlobalsService {

  static instance: ApplicationGlobalsService;
  private _globalConfigurations: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  private _localization: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private _baseLocalization: BehaviorSubject<any> = new BehaviorSubject<any>({}); // base language is english
  private _users: BehaviorSubject<UserDTO[]> = new BehaviorSubject([]);
  private _apps: BehaviorSubject<AppDTO[]> = new BehaviorSubject([]);

  constructor() {
    return ApplicationGlobalsService.instance = ApplicationGlobalsService.instance || this;
  }

  setGlobalConfigurations = () => {
    const globalConfiguration = require('../../environments/environment');
    this._globalConfigurations.next(globalConfiguration.environment);
  }

  get globalConfigurations(): any {
    return this._globalConfigurations.value;
  }

  set user(user: User) {
    this._user.next(user);
  }

  get user() {
    return this._user.value;
  }

  set localization(localizaionFile: any) {
    this._localization.next(localizaionFile);
  }

  get localization(): any {
    return this._localization.value;
  }

  set baseLocalization(localizaionFile: any) {
    this._baseLocalization.next(localizaionFile);
  }

  get baseLocalization(): any {
    return this._baseLocalization.value;
  }
}
