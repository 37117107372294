import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { CustomersManager } from 'app-managers/customers.manager';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { UserDTO } from 'app-models/user/user-dto.model';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { CustomerPermissionsTableConfiguration } from './configurations/customer-permissions-table.configuration';
import { CustomerPermissionsTableColumnsConfiguration } from './configurations/customer-permissions-table-columns.configuration';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {

  constructor(private customersManager: CustomersManager) { }

  getConfigurations = () => {
    const permissionsTableConfig = CustomerPermissionsTableConfiguration;
    const permissionsColumnsConfig = deepCloneObject(CustomerPermissionsTableColumnsConfiguration);
    return { permissionsTableConfig, permissionsColumnsConfig };
  }

  getCustomer = (id: CustomerDTO['id']): Observable<CustomerDTO> => {
    return this.customersManager.getCustomer(id).pipe(map((res) => res.response));
  }

  updateCustomerDetails = (id: CustomerDTO['id'], newCustomerDetails: Partial<CustomerDTO>): Observable<{ success: boolean; }> => {
    return this.customersManager.updateCustomerDetails(id, newCustomerDetails).pipe(
      map((res) => res.response)
    );
  }

  changeCustomerStatus = (id: CustomerDTO['id'], status: boolean) => {
    return this.customersManager.changeCustomerStatus(id, status);
  }

  getCustomerAssignedUsers = (id: CustomerDTO['id']): Observable<UserDTO[]> => {
    return this.customersManager.getCustomerAssignedUsers(id).pipe(
      map((res) => res.response?.data )
    );
  }

  getCustomerUnassignedUsers = (id: CustomerDTO['id']): Observable<UserDTO[]> => {
    return this.customersManager.getCustomerUnassignedUsers(id).pipe(
      map((res) => res.response?.data )
    );
  }

  assignUsersToCustomer = (id: CustomerDTO['id'], usersToAssign: UserDTO['id'][]) => {
    return this.customersManager.assignUsersToCustomer(id, usersToAssign).pipe(
      map((res) => res.response)
    );
  }

  unassignUserFromCustomer = (id: CustomerDTO['id'], userId: UserDTO['id']) => {
    return this.customersManager.unassignUserFromCustomer(id, userId).pipe(
      map((res) => res.response)
    );
  }

  getPermissions = (id: CustomerDTO['id']) => {
    return this.customersManager.getPermissions(id).pipe(map((res) => {
      return {
        permissions: res.response['data'],
        appsMap: res.response['map'],
      };
    }));
  }

  updatePermission = (id: CustomerDTO['id'], permissionId: number, applicationId: number, status: boolean) => {
    if (status) {
      return this.customersManager.addPermission(id, permissionId, applicationId);
    }
    else {
      return this.customersManager.deletePermission(id, permissionId, applicationId);
    }
  }

  getExpiryIcon = (expiry_date: number) => {
    let expiryIcon;
    if (expiry_date <= moment().valueOf()) {
      expiryIcon = { class: 'far fa-calendar expired', tooltip: { text: 'customer_expired', needsTranslation: true } };
    } else if (moment(Number(expiry_date)).utc().subtract(30, 'days').valueOf() <= moment().valueOf()) {
      expiryIcon = { class: 'far fa-calendar expiring-soon', tooltip: { text: 'customer_expiring_soon', needsTranslation: true } };
    }
    return expiryIcon;
  }

}
