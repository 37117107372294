export enum TableCellType {
  Text = 'text',
  Date = 'date',
  Image = 'image',
  Icon = 'icon',
  Chips = 'chips',
  Link = 'link',
  Actions = 'actions',
  Badge = 'badge',
  Checkbox = 'checkbox',
  InputSwitch = 'inputSwitch',
}
