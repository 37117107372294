import { Injectable } from '@angular/core';
import { LoginManager } from 'app-managers/login.manager';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Observable';
import { RequestResult } from 'app-models/common/requestResult.model';
import { _throw } from 'rxjs/observable/throw';

@Injectable()
export class LoginPageService {
  constructor(private loginManager: LoginManager) { }

  login = (username, password, code, app, returnUrl, changePassword): Observable<any> => {
    let requestResult: RequestResult = new RequestResult();
    return this.loginManager.login(username, password, code, app, returnUrl, changePassword)
      .map((result: any) => {
        requestResult = result;
        return requestResult;
      })
      .catch((error: any) => {
        return _throw(error);
      });
  }


}
