import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { UserCardModel } from 'app-models/user/user-card.model';
import { UserDTO } from 'app-models/user/user-dto.model';
import { Notification } from 'app-models/notification/notification.model';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  @Input() user: UserDTO;
  @Input() config: UserCardModel;
  @Output() notify: EventEmitter<Notification> = new EventEmitter();

  fullName: string;

  constructor() {
  }

  ngOnInit(): void {
    this.fullName = (this.user.first_name || '') + ' ' + (this.user.last_name || '');
  }

  handleCardNotification = (notification: Notification): void => {
    this.notify.emit(notification);
  }

}
