import { Injectable } from '@angular/core';
import { UsersManager } from 'app-managers/users.manager';
import { UserDTO } from 'app-models/user/user-dto.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  constructor(private userManager: UsersManager) { }

  getUser = (id: UserDTO['id']): Observable<UserDTO> => {
    return this.userManager.getUser(id).pipe(map((res) => res.response));
  }

  generatePassword = (id: UserDTO['id']): Observable<{ password: string }> => {
    return this.userManager.generatePassword(id).pipe(
      map((res) => res.response)
    );
  }

  changePassword = (newPassword: string): Observable<{ success: boolean, message?: string; }> => {
    return this.userManager.changePassword(newPassword).pipe(
      map((res) => res.response)
    );
  }

  disableTFA = (id: UserDTO['id']) => {
    return this.userManager.disableTFA(id);
  }

  updateUserDetails = (id: UserDTO['id'], newUserDetails: Partial<UserDTO>): Observable<{ success: boolean; }> => {
    return this.userManager.updateUserDetails(id, newUserDetails).pipe(
      map((res) => res.response)
    );
  }

  deleteUser = (id: UserDTO['id']) => {
    return this.userManager.deleteUser(id);
  }

  getPermissions = (id: UserDTO['id']) => {
    return this.userManager.getPermissions(id).pipe(map((res) => {
      return {
        permissions: res.response['data'],
        appsMap: res.response['map'],
      };
    }));
  }

  addPermissions = (id: UserDTO['id'], permissions: { permissionId: number, applicationId: number }[]) => {
    return this.userManager.addPermissions(id, permissions);
  }

  deletePermission = (id: UserDTO['id'], permissionId: number, applicationId: number) => {
    return this.userManager.deletePermission(id, permissionId, applicationId);
  }

  disableUser = (id: UserDTO['id'], status: boolean) => {
    return this.userManager.disableUser(id, status);
  }

  saveApplications = (id: UserDTO['id'], applicationIds: number[]) => {
    return this.userManager.saveApplications(id, applicationIds).pipe(
      map((res) => res.response)
    );
  }
}
