import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { UserService } from 'app-services/user.service';
import { UserPermissionEnum } from '../models/user/permission.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const permissions: UserPermissionEnum = route.data?.allowedPermissions;
    if (!permissions) {
      return true;
    }

    const allowed = this.userService.hasPermission(permissions);

    if (!allowed) {
      this.router.navigate(['/error']);
    }
    return allowed;
  }
}
