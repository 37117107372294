import { Injectable } from '@angular/core';
import { AppsManager } from 'app-managers/apps.manager';
import { Observable } from 'rxjs/Observable';
import { AppDTO } from 'app-models/app/app-dto.model';
import { AddNewApp } from 'app-models/request/addNewApp.model'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppsPageService {

  constructor(private appsManager: AppsManager) { }

  getAll = (): Observable<AppDTO[]> => {
    return this.appsManager.getAll().pipe(
      map((res) => res.response?.data )
    )
  }

  addNewApp = (newAppDetails: Partial<AppDTO>): Observable<AddNewApp> => {
    return this.appsManager.addNewApp(newAppDetails).pipe(
      map((res) => res.response)
    );
  }
}
