import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersPageService } from './users-page.service';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { UserDTO } from 'app-models/user/user-dto.model';
import { DialogService } from 'primeng/dynamicdialog';
import { AddUserComponent } from 'app-components/add-user/add-user.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LogoHelper } from 'app-helpers/logo.helper';
import { isEmpty } from 'lodash';
import { TranslationService } from 'app-services/translation.service';
import { copyToClipboard } from 'app-scripts/utilities/general';
import { SearchInputComponent } from 'app-components/inputs/search/search.component';
import { User } from 'app-models/user/user.model';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';

@Component({
  selector: 'app-users-page',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
  providers: [DialogService]
})
export class UsersPageComponent extends ComponentWithSubscriptions implements OnInit {

  @ViewChild('search') searchInput: SearchInputComponent;

  users: UserDTO[];
  dialog: DialogService;
  filteredUsers: UserDTO[];
  searchTerm: string;

  constructor(
    private userPageService: UsersPageService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private logoHelper: LogoHelper,
    private translation: TranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.users = [];
    this.subscriptions.push(this.userPageService.getAll().subscribe(users => {
      this.users = users;
      this.filteredUsers = users;
    }));
  }

  notificationReceived(notification: Notification): void {
    switch (notification.type) {
      case NotificationTypeEnum.AutocompleteSearch:
      case NotificationTypeEnum.ClearSearch: {
        this.searchTerm = notification.data;
        this.filterUsers();
        break;
      }
    }
  }

  applyFilters = (user: UserDTO): boolean => {
    let passFilters = true;

    if (!isEmpty(this.searchTerm)) {
      const values = user.first_name.concat(user.last_name).concat(user.email).toLowerCase().trim();
      passFilters = passFilters && (values.includes(this.searchTerm.toLowerCase().trim()));
    }

    return passFilters;
  }

  filterUsers = () => {
    this.filteredUsers = this.users?.filter((user) => this.applyFilters(user));
    this.searchInput?.searchFinished();
  }

  openCreateUserDialog() {
    const ref = this.dialogService.open(AddUserComponent, { header: this.translation.translate('create_user') });
    this.subscriptions.push(ref.onClose.subscribe((newUserDetails) => {
      this.createNewUser(newUserDetails);
    }));
  }

  createNewUser = (newUserDetails: any) => {
    if (isEmpty(newUserDetails)) { return; }
    this.subscriptions.push(this.userPageService.createNewUser(newUserDetails).subscribe((res) => {
      if (res?.success) {
        this.userCreatedDialog(res);
        const userLogoUrl = this.logoHelper.generateLogoUrl(`${res.newUser.first_name || ''}+${res.newUser.last_name || ''}`);
        res.newUser.logoUrl = userLogoUrl;
        this.users.push(res.newUser);
      }
    }, (err) => {
      this.userCreationFailedToast();
    }));
  }

  userCreatedDialog(res: any) {
    this.confirmationService.confirm({
      key: 'messageDialog',
      message: `${this.translation.translate('user_creation_success')}. ${this.translation.translate('new_generated_password')} <b>${res.password}</b>`,
      icon: 'pi pi-info-circle',
      header: this.translation.translate('user_created'),
      accept: () => copyToClipboard(res.password),
      acceptLabel: this.translation.translate('copy_clipboard'),
      acceptIcon: 'fas fa-clipboard',
      rejectLabel: this.translation.translate('dismiss'),
    });
  }

  userCreationFailedToast() {
    this.messageService.add({
      severity: 'error',
      summary: this.translation.translate('error'),
      detail: this.translation.translate('user_creation_err'),
      key: 'toastMessage',
    });
  }
}
