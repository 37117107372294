import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { RequestResult } from 'app-models/common/requestResult.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class LoginManager {

  readonly loginControllerName = 'login';
  readonly logoutControllerName = 'logout';

  constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
  ) { }

  login = (email: string, password: string, code: string, app: string, returnUrl: string, changePassword: boolean): Observable<RequestResult> => {
    const requestResult: RequestResult = new RequestResult();
    let url = this.urlService.getRequestUrl(this.loginControllerName);
    returnUrl = encodeURIComponent(returnUrl);
    url += `?app=${app}&returnUrl=${returnUrl}`;
    return this.httpRequestService.post(url, { email, password, code, changePassword }, true)
      .pipe(map((response: HttpResponse<any>) => {
        requestResult.response = response.body.data;
        requestResult.raw = response;
        return requestResult;
      }))
      .catch(error => {
        throw (error);
      });
  }

  setup = (email: string, password: string): Observable<RequestResult> => {
    const requestResult: RequestResult = new RequestResult();
    const url = this.urlService.getRequestUrl('tfa/setup');
    return this.httpRequestService.post(url, { email, password }, true)
      .pipe(map((response: HttpResponse<any>) => {
        requestResult.response = response.body.data;
        requestResult.raw = response;
        return requestResult;
      }))
      .catch(error => {
        throw (error);
      });
  }

  verify = (email: string, password: string, code: number | string): Observable<RequestResult> => {
    const requestResult: RequestResult = new RequestResult();
    const url = this.urlService.getRequestUrl('tfa/verify');
    return this.httpRequestService.post(url, { email, password, code }, true)
      .pipe(map((response: HttpResponse<any>) => {
        requestResult.response = response.body.data;
        requestResult.raw = response;
        return requestResult;
      }))
      .catch(error => {
        throw (error);
      });
  }

  logout() {
    const url = this.urlService.getRequestUrl(this.logoutControllerName);
    return this.httpRequestService.get(url);
  }

}
