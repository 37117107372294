import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.view.html'
})

export class AppComponent {
    constructor() {}
}
