import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TableConfiguration } from 'app-models/table/table-configuration.model';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { Table } from 'primeng/table';
import { DefaultTableConfiguration } from './configurations/default-table.coniguration';
import { isEmpty, isArray } from 'lodash';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { SearchInputComponent } from 'app-components/inputs/search/search.component';
import { SearchInputConfig } from './configurations/search-input.configuration';
import { deepCloneObject } from 'app-scripts/utilities/general';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {

  @Input() data: any[];
  @Input() columns: TableCellConfiguration<any>[];
  @Input() config: TableConfiguration;
  @Output() notification: EventEmitter<Notification> = new EventEmitter();
  @ViewChild('search') searchInput: SearchInputComponent;
  @ViewChild('table') table: Table;
  searchInputConfig = SearchInputConfig;
  totalItems: number;

  tableValue: any[];
  tableFiltersActive: boolean;
  externalFiltersActive: boolean;

  searchableProperties: string[];

  constructor() { }

  ngOnChanges(): void {
    this.tableValue = this.data;
    if (!isEmpty(this.data)) {
      this.totalItems = this.data.length;
      // trigger ngOnChanges
      // this.tableValue = undefined;
      this.searchableProperties = this.columns
        .filter((col: TableCellConfiguration<any>) => col.searchable)
        .map((col: TableCellConfiguration<any>) => col.field);
      setTimeout(() => {
        if (!isEmpty(this.searchInput?.savedSearchTerm)) {
          this.filterTable(this.searchInput?.savedSearchTerm);
        } else {
          this.tableValue = this.data;
        }
      }, 0);
    }

    if (!this.config) {
      this.config = DefaultTableConfiguration;
    }
  }

  handleTableCellNotification = (notification: Notification): void => {
    this.notification.emit(notification);
  }

  clearExternal = (): void => {
    this.externalFiltersActive = false;
  }

  filterExternal = (): void => {
    this.externalFiltersActive = true;
  }

  clear = (table: Table): void => {
    table.clear();
    this.searchInput?.clear();
    this.notification.emit({
      type: NotificationTypeEnum.ClearSearch
    });
    this.tableFiltersActive = false;
  }

  filter = (table: Table, searchTerm: string): void => {
    if (table) {
      this.filterTable(searchTerm);
      if (this.searchInput) {
        this.searchInput.data.searchInProgress = false;
      }

      this.tableFiltersActive = !isEmpty(searchTerm);
    }
  }

  isRowContainsSearchTerm = (row: any, property: any, searchTerm: string) => {
    if (row[property]) {
      if (typeof row[property] === 'string') {
        return row[property].toLowerCase().contains(searchTerm.toLowerCase());
      }

      if (typeof row[property] === 'number') {
        return String(row[property]).contains(searchTerm.toLowerCase());
      }

      if (isArray(row[property])) {
        const isNotStringsArray = row[property].some((arrayItem: any) => typeof arrayItem !== 'string');
        return isNotStringsArray ? false : row[property].join(' ').toLowerCase().contains(searchTerm.toLowerCase());
      }
    }
    return false;
  }

  filterTable = (searchTerm: string) => {
    if (!isEmpty(searchTerm)) {
      this.tableValue = deepCloneObject(this.data).filter((row: any) =>
        this.searchableProperties.some((property: string) => {
          return this.isRowContainsSearchTerm(row, property, searchTerm);
        })
      );
    } else {
      this.tableValue = this.data;
    }

  //   this.notification.emit({
  //     type: NotificationTypeEnum.ValueChanged,
  //     data: {
  //       tableData: !isEmpty(searchTerm) ? this.tableValue : this.data
  //     }
  //   });
  // }

  // footerActionClick = (action: UserActionType): void => {
  //   this.notification.emit({
  //     type: NotificationTypeEnum.ItemClicked,
  //     data: { action }
  //   });
  // }
  }
}
