export class Text {
  text: string;
  needsTranslation: boolean;
  parameters?: string[];

  constructor(text: string, needsTranslation: boolean = false, parameters?: string[]) {
    this.text = text;
    this.needsTranslation = needsTranslation;
    this.parameters = parameters;
  }
}
