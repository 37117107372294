import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { Notification } from 'app-models/notification/notification.model';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SearchBox } from 'app-models/inputs/search-box.model';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { deepCloneObject } from 'app-scripts/utilities/general';
import { DefaultSearchInputConfig } from './configurations/default.configuration';
import _ from 'lodash';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Image } from 'app-configurations/images.configuration';
import { ImageService } from 'app-services/image.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'search-input',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchInputComponent extends ComponentWithSubscriptions implements OnInit {
  @Input() data: SearchBox;
  @Input() searchTerm: any;
  @Output() notify: EventEmitter<Notification> = new EventEmitter();
  searchTermChanged: Subject<string> = new Subject<string>();
  searchImage: string;
  savedSearchTerm: string;

  constructor(private imageService: ImageService) {
    super();
  }

  ngOnInit(): void {
    this.data = this.data || deepCloneObject(DefaultSearchInputConfig);
    this.searchImage = this.imageService.getImage(Image.search);

    this.searchTermChanged
    .pipe(debounceTime(1000), distinctUntilChanged())
    .subscribe(() => {
      if (this.savedSearchTerm !== this.searchTerm) {
        this.savedSearchTerm = this.searchTerm;
        this.searchValueUpdated();
      }
    });
  }

  onSearchValueChange = (query?: string) => {
    this.searchTermChanged.next(query);
  }

  clear = () => {
    this.searchTerm = undefined;
    this.savedSearchTerm = undefined;
    this.searchTermChanged.next(undefined);
    this.notify.emit({ type: NotificationTypeEnum.ClearSearch });
  }

  searchValueUpdated = (): any => {
    this.data.searchInProgress = true;
    this.data.showSearchInProgress = true;
    this.notify.emit({ type: NotificationTypeEnum.AutocompleteSearch, data: this.searchTerm });
  }

  searchFinished = (): void => {
    this.data.searchInProgress = false;
  }
}
