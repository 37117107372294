import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Roles } from 'app-models/roles/roles.enum';
import { UserService } from 'app-services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedRoles: Roles[] = route.data?.allowedRoles;
    if (!allowedRoles) {
      return true;
    }

    const allowed = this.userService.hasRole(allowedRoles);

    if (!allowed) {
      this.router.navigate(['/error']);
    }
    return allowed;
  }
}
