import { Injectable } from '@angular/core';
import { LocalizationService } from './localization.service';
import { getEnumKeyByValue } from 'app-scripts/utilities/general';
import { UserLanguage } from 'app-models/user/user.module';
import { UserService } from './user.service';

@Injectable()
export class TranslationService {
    constructor(
        private localizationService: LocalizationService,
        private userService: UserService
    ) { }

    translate = (value: string): string | any => {
        let result: string;
        if (value === undefined || value === null || value.isEmptyOrNullOrUndefined()) { return ''; }
        const translatedValue = this.localizationService.getLanguageValue(value);
        if (translatedValue) {
            result = translatedValue;
        }
        else {
            const baseValue = this.localizationService.getBaseLanguageValue(value);
            if (baseValue) {
                result = baseValue;
            }
            else {
                result = value;
            }
            const language = getEnumKeyByValue(UserLanguage, this.userService.language);
            console.error(`'${value}' should be translated to: ${language}`);
        }

        return result;
    }
}
