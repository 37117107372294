import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { ActivatedRoute, Router } from '@angular/router';
import { share } from 'rxjs/operators/share';
import { ConfirmationService, MessageService } from 'primeng/api';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { Text } from 'app-models/common/text.model';
import { TranslationService } from 'app-services/translation.service';
import { ToastMessagesConfiguration } from 'app-configurations/toast-messages.cofiguration';
import { PermissionDTO } from 'app-models/permission/permission-dto.model';
import { Severity } from 'app-models/toast/severity.enum';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CustomerDetailsService } from './customer-details.service';
import { CountriesHelper } from 'app-helpers/countries.helper';
import { UserDTO } from 'app-models/user/user-dto.model';
import { UserCardModel } from 'app-models/user/user-card.model';
import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { Country } from 'app-models/common/country.model';
import moment from 'moment';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { Roles } from 'app-models/roles/roles.enum';
import { isEqual } from 'lodash';
import { Icon } from 'app-models/common/icon.model';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent extends ComponentWithSubscriptions implements OnInit {
  readonly NotificationTypeEnum = NotificationTypeEnum;
  readonly Roles = Roles;
  customer: CustomerDTO;
  detailsForm: FormGroup;
  assignUserForm: FormGroup;
  customerDisabled: boolean;
  customerDetailsChanged: boolean;
  activeFragment = this.activatedRoute.fragment.pipe(share());
  dialog: ConfirmationService;
  permissions: PermissionDTO[];
  assignedUsers: UserDTO[];
  unassignedUsers: UserDTO[];
  userToAssign: UserDTO;
  cardConfig: UserCardModel;
  countries: Country[];
  expiryDateString: string;
  expiryDate: Date;
  expiryIcon: Icon;
  configurations: any;
  permissionsTable: any;
  appMap: any;
  todayDate: Date;

  constructor(
    private customerDetailsService: CustomerDetailsService,
    private countriesHelper: CountriesHelper,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private translation: TranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.todayDate = new Date();
    this.configurations = this.customerDetailsService.getConfigurations();
    this.permissionsTable = {
      config: this.configurations.permissionsTableConfig,
      columns: this.configurations.permissionsColumnsConfig
    };
    this.countries = this.countriesHelper.getCountries().map((country) => ({ 'label': country.name, 'value': country.name }));
    this.detailsForm = new FormGroup({
      name: new FormControl('', Validators.required),
      country: new FormControl(null, Validators.required),
    });
    this.assignUserForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
    this.customer = undefined;
    this.cardConfig = { actions: [UserActionType.Delete] };
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.subscriptions.push(
      this.customerDetailsService
        .getCustomer(id as unknown as number)
        .subscribe((customer: CustomerDTO) => {
          if (customer) {
            this.customer = customer;
            const { name, country } = customer;
            this.detailsForm.setValue({ name, country });
            this.customerDisabled = customer.disabled;
            this.expiryDateString = customer.expiry_date ? moment.utc(Number(customer.expiry_date)).format("MMM Do YYYY") : undefined;
            this.expiryIcon = customer.expiry_date ? this.customerDetailsService.getExpiryIcon(customer.expiry_date) : undefined;
          } else {
            this.router.navigate(['/error']);
          }
        })
    );

    this.subscriptions.push(
      this.detailsForm.valueChanges
        .pipe(debounceTime(1000), distinctUntilChanged(isEqual))
        .subscribe((newValue) => {
          const { name, country } = this.customer;
          this.customerDetailsChanged = !isEqual(newValue, { name, country });
        })
    );

    this.subscriptions.push(
      this.customerDetailsService
        .getCustomerAssignedUsers(
          parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)
        )
        .subscribe((users) => {
          this.assignedUsers = users;
        })
    );

    this.subscriptions.push(
      this.customerDetailsService
        .getCustomerUnassignedUsers(
          parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)
        )
        .subscribe((users) => {
          this.unassignedUsers = users;
        })
    );

    this.subscriptions.push(this.customerDetailsService.getPermissions(parseInt(this.activatedRoute.snapshot.paramMap.get('id')))
      .subscribe((result) => {
        result.permissions.forEach((permission) => {
          permission.name = permission.name.toDisplayText();
        });
        this.permissionsTable.data = result.permissions;
        this.appMap = result.appsMap;
        Object.keys(this.appMap).forEach((app) => {
          this.permissionsTable.columns.push({
            header: `${app}`,
            field: `${app}`,
            types: [TableCellType.Checkbox],
            width: '25px',
          });
        });
      }));
  }

  saveCustomer = () => {
    const newCustomerDetails: Partial<CustomerDTO> = this.detailsForm.value;
    this.subscriptions.push(
      this.customerDetailsService
        .updateCustomerDetails(this.customer.id, newCustomerDetails)
        .subscribe((res) => {
          if (res?.success) {
            Object.keys(newCustomerDetails).forEach(
              (key) => (this.customer[key] = newCustomerDetails[key])
            );
            this.customer.logoUrl = this.countriesHelper.getFlagPath(
              this.customer.country
            );
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_UPDATED,
              Severity.Success
            );
            setTimeout(() => {
              this.customerDetailsChanged = false;
            }, 10);
          } else {
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_UPDATED_ERROR,
              Severity.Error
            );
          }
        })
    );
  }

  cancelEditCustomer = () => {
    const { name, country } = this.customer;
    this.detailsForm.setValue({ name, country });
    this.customerDetailsChanged = false;
  }

  onUserChange = (user: UserDTO) => {
    this.userToAssign = user;
  }

  openActivateCustomerDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('customer_activation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('activate'),
      accept: () => {
        this.activateCustomer();
      },
    });
  }

  activateCustomer() {
    this.subscriptions.push(
      this.customerDetailsService
        .changeCustomerStatus(this.customer.id, false)
        .subscribe((res) => {
          if (res) {
            this.customerDisabled = false;
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_ACTIVATED,
              Severity.Success
            );
          } else {
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_ACTIVATED_ERROR,
              Severity.Error
            );
          }
        })
    );
  }

  openDeactivateCustomerDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('customer_deactivation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('deactivate'),
      accept: () => {
        this.deactivateApp();
      },
    });
  }

  deactivateApp() {
    this.subscriptions.push(
      this.customerDetailsService
        .changeCustomerStatus(this.customer.id, true)
        .subscribe((res) => {
          if (res) {
            this.customerDisabled = true;
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_DEACTIVATED,
              Severity.Success
            );
          } else {
            this.addToast(
              ToastMessagesConfiguration.CUSTOMER_DEACTIVATED_ERROR,
              Severity.Error
            );
          }
        })
    );
  }

  notificationReceived(notification: Notification, user?: UserDTO): void {
    switch (notification.type) {
      case NotificationTypeEnum.ItemClicked: {
        if (notification.data === UserActionType.Delete) {
          this.subscriptions.push(
            this.customerDetailsService
              .unassignUserFromCustomer(this.customer.id, user.id)
              .subscribe((res) => {
                if (res) {
                  const index = this.assignedUsers.indexOf(user);
                  this.assignedUsers.splice(index, 1);
                  this.unassignedUsers.push(user);
                  this.addToast(
                    ToastMessagesConfiguration.USER_UNASSIGNED,
                    Severity.Success
                  );
                } else {
                  this.addToast(
                    ToastMessagesConfiguration.USER_UNASSIGNED_ERROR,
                    Severity.Error
                  );
                }
              })
          );
        }
      }
      case NotificationTypeEnum.ItemSelected:
        const data = notification.data;
        this.subscriptions.push(this.customerDetailsService.updatePermission(
          this.customer.id, data.row.id, this.appMap[data.field], data.row[data.field]).subscribe((res) => {
            if (!res) {
              throw new Error();
            }
          }));
        break;
    }
  }

  assignUserToCustomer = () => {
    const usersToAssign = this.assignUserForm.value.name;
    const usersToAssignIds = usersToAssign.map((user) => user.id);
    this.subscriptions.push(
      this.customerDetailsService
        .assignUsersToCustomer(this.customer.id, usersToAssignIds)
        .subscribe((res) => {
          if (res.success) {
            this.assignUserForm.reset();
            usersToAssign.forEach((user) => {
              this.unassignedUsers.splice(this.unassignedUsers.indexOf(user), 1);
              this.assignedUsers.push(user);
            });
            this.addToast(
              ToastMessagesConfiguration.USER_ASSIGNED,
              Severity.Success
            );
          } else {
            this.addToast(
              ToastMessagesConfiguration.USER_ASSIGNED_ERROR,
              Severity.Error
            );
          }
        })
    );
  }

  saveExpiryDate = () => {
    const expiryDate = this.expiryDate ? moment(this.expiryDate).utc(true).endOf('day').valueOf() : null;
    const newCustomerDetails: Partial<CustomerDTO> = { expiry_date: expiryDate }
    this.subscriptions.push(
      this.customerDetailsService.updateCustomerDetails(this.customer.id, newCustomerDetails).subscribe((res) => {
        if (res?.success) {
          this.customer.expiry_date = expiryDate;
          this.expiryDateString = expiryDate ? moment.utc(Number(expiryDate)).format("MMM Do YYYY") : undefined;
          this.expiryIcon = expiryDate ? this.customerDetailsService.getExpiryIcon(expiryDate) : undefined;
          this.addToast(
            ToastMessagesConfiguration.EXPIRY_DATE_UPDATED,
            Severity.Success
          );
        } else {
          this.addToast(
            ToastMessagesConfiguration.EXPIRY_DATE_ERROR,
            Severity.Error
          );
        }
      })
    );
  }

  addToast = (message: Text, severity: string) => {
    const detail = message.needsTranslation
      ? this.translation.translate(message.text)
      : message.text;
    this.messageService.add({
      severity,
      summary: this.translation.translate(severity),
      detail,
      key: 'toastMessage',
    });
  }
}
