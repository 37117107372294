import { Component, OnInit } from '@angular/core';
import { ComponentsNamesPerRouteEnum } from 'app-models/page/componentsNamesPerRouteEnum';
import { PageNavigatorConfigurations } from 'app-components/page-navigator/page-navigator.configurations';
import { UserService } from 'app-services/user.service';
import { TopBarService } from './top-bar.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  imageSrc: string;
  pageTitle: string;
  routes: any[];
  homePageRoute: any;

  constructor(
    private userService: UserService,
    private topBarService: TopBarService
  ) { }

  ngOnInit(): void {
    this.routes = this.topBarService.getRoutes();
    this.homePageRoute = [PageNavigatorConfigurations.routes[ComponentsNamesPerRouteEnum.Users].route];
  }

  logoutUser = () => {
    this.userService.logout();
  }
}
