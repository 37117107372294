import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CustomersManager } from 'app-managers/customers.manager';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private customersManager: CustomersManager) { }

  getAll = (): Observable<CustomerDTO[]> => {
    return this.customersManager.getAll().pipe(
      map((res) => res.response?.data )
    );
  }

  createNewCustomer = (newCustomerDetails: Partial<CustomerDTO>): Observable<{ success: boolean, newCustomer: CustomerDTO }> => {
    return this.customersManager.createNewCustomer(newCustomerDetails).pipe(
      map((res) => res.response)
    );
  }

  getExpiryDate = () => {
    return this.customersManager.getExpiryDate().pipe(
      map((res) => res.response)
    );
  }

}
