import { UserActionsConfigItem } from 'app-models/actions/user-actions-config-item.model';
import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { GenericObject } from 'app-models/common/generic-object';
import { Text } from 'app-models/common/text.model';

export const UserActionsConfiguration: GenericObject<UserActionsConfigItem> = {
  [UserActionType.Edit]: {
    icon: { class: 'far fa-edit', tooltip: new Text('edit', true) }
  },
  [UserActionType.Delete]: {
    icon: { class: 'fal fa-trash', tooltip: new Text('delete', true) }
  },
  [UserActionType.Add]: {
    icon: { class: 'far fa-plus-circle', tooltip: new Text('user_actions.add', true) }
  },
  [UserActionType.Settings]: {
    icon: { class: 'far fa-cog', tooltip: new Text('user_actions.settings', true) }
  }
};
