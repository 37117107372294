import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { AppPermissionsResult } from 'app-models/permission/app-permissions-result';

export const CustomerPermissionsTableColumnsConfiguration: TableCellConfiguration<AppPermissionsResult>[] = [
  {
    header: 'Permission Name',
    field: 'name',
    types: [TableCellType.Text],
    width: '50px',
    styleClass: 'capitalize',
    resizeable: true,
  },
];
