
import { HttpHeaders } from '@angular/common/http';
import * as lodash from 'lodash';

// check if object has values inside
export function ObjectHasProperties(obj: any) {
    if (obj === undefined) {
        obj = {};
    }
    return Object.getOwnPropertyNames(obj).length > 0;
}

export function getHttpRequestOptions() {
    const headers = new HttpHeaders();
    headers
        .set('Content-Type', 'application/json')
        .set('json', 'true');
    return ({
        headers: headers,
        withCredentials: true
    });
}

export function getEnumKeyByValue<T>(myEnum: T, value: string): string {
    let result: string;
    const keys = Object.keys(myEnum);
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (myEnum[key] === value) {
            result = key;
            break;
        }
    }
    return result;
}

export function getEnumKeysArray<T extends object>(enumObject: T, valueType: string): string[] {
  return Object.keys(enumObject).filter(k => typeof enumObject[k] === valueType);
}

export function getEnumValuesArray<U>(enumObject: object, valueType: string): U[] {
  return getEnumKeysArray(enumObject, valueType).map(k => enumObject[k]);
}

export class Guid {
    constructor(public guid: string) {
        this._guid = guid;
    }

    private _guid: string;

    // Static member
    static create(): Guid {
        let result: string;
        let i: string;
        let j: number;

        result = '';
        for (j = 0; j < 32; j++) {
            if (j === 8 || j === 12 || j === 16 || j === 20) {
                result = result + '-';
            }
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return new Guid(result);
    }

    public ToString(): string {
        return this.guid;
    }
}

export function getSubStringByStardAndEndIndex(year: string, startIndx: number, endIndx: number): string {
    return year.slice(startIndx, endIndx);
}

export function copyToClipboard(value: any): any {
    document.addEventListener('copy', (e: ClipboardEvent) => {
        const ctrlPusKeyC = window.getSelection().toString();
        value = ctrlPusKeyC === '' ? value : ctrlPusKeyC;
        e.clipboardData.setData('text/plain', (value));
        e.preventDefault();
        document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
}

export function htmlToText(value: string): string {
    return value.replace(/<\/?[^>]+(>|$)/g, '');
}

export function deepCloneObject(object: any): any {
    return lodash.cloneDeep(object);
}

export function getUrlParams(url: string): any {
    if (url === undefined) { return {}; }
    url = url.split('+').join(' ');
    let tokens: string[] | RegExpExecArray;
    const params = {}, re = /[?&]?([^=]+)=([^&]*)/g;
    while (tokens = re.exec(url)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}

/**
 * @example alert(String.Format("Hello {0}. Yes, hello {0} again. My name is {1}", "world", "Miron"));
 * @param format
 * @param args
 */
export function stringFormat(format: string, args: string[]) {
    let result = format;
    for (let i = 1; i <= args.length; i++) {
        if (typeof (args[i - 1]) === 'string') {
            result = result.replace(new RegExp('\\{' + (i - 1) + '\\}', 'g'), args[i - 1]);
        }
        else if (Array.isArray(args[i - 1])) {
            let replacement = '';
            const arg = args[i - 1];
            for (let index = 0; index < arg.length; index++) {
                const element = arg[index];
                replacement += '"' + element + '",';
            }
            replacement = replacement.slice(0, -1);
            result = result.replace(new RegExp('\\{' + (i - 1) + '\\}', 'g'), replacement);
        }
    }
    return result;
}

export function getPercentInPixels(percent: number, fullSize: number) {
    return (percent / 100) * fullSize;
}

/**
 * Get the diff between tow arrays
 * @param list
 * @param originalList
 */
export function getArrayDiff(list: string[], originalList: string[]): any {
    const added = list.filter((x: any) => !originalList.includes(x));
    const removed = originalList.filter((x: any) => !list.includes(x));

    return {
        removed, added
    };
}

export function getArray<T>(element: T | T[]): T[] {
  if (Array.isArray(element)) {
    return element;
  }

  return [element];
}

export function getObjArrayDiff(list: string[], originalList: any[], field: string): any {
    const added = list.filter(comparer(originalList, field));
    const removed = originalList.filter(comparer(list, field));

    return {
        removed, added
    };
}

function comparer(otherArray: any, field: any): any {
    return function (current: { [x: string]: any; }) {
        return otherArray.filter(function (other: { [x: string]: any; }) {
            return other[field] === current[field];
        }).length === 0;
    };
}

export function getSeverityNumber(severity: string): number {
    switch (severity) {
        case 'low':
            return 1;
        case 'medium':
            return 2;
        case 'high':
            return 3;
        case 'critical':
            return 4;
    }
}

export function openUrlInNewTab(url: string) {
    const win = window.open(url, '_blank');
    win.focus();
}

export function findWordIndex(str: string, searchingFor: string) {
    const needle = searchingFor;
    const re = new RegExp(needle, 'gi');
    const haystack = str;

    const results = [];
    while (re.exec(haystack)) {
        results.push({ index: re.lastIndex - searchingFor.length, length: searchingFor.length });
    }
    return results;
}

export function parseHtml(line: string) {
    const el = document.createElement('html');
    el.innerHTML = line;
    return el.innerText;
}

export function getEscapedRows(text: string) {
    const rows = text.split('\n');
    return rows.map(row => {
        return parseHtml(row);
    });
}

export function getEscapedText(text: string) {
    const rows = text.split('\n');
    const r = rows.map(row => {
        return parseHtml(row);
    });
    return r.join('\n');
}

export function getAcronyms(value: string) {
    value = value.trim();
    const splitedValue = value.split(' ');
    return `${splitedValue[0].getFirstLetter().toUpperCase()}${splitedValue[splitedValue.length - 1].getFirstLetter().toUpperCase()}`;
}

export function BigNumbersSuffix(value: number): string {

    if (value <= 999) {
        return value + '';
    }
    else if (value >= 1000 && value <= 999999) {
        return (value / 1000).toFixed(1) + 'K';
    }
    else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000).toFixed(1) + 'M';
    }
    else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000).toFixed(1) + 'B';
    }
    else {
        return value + '';
    }
}

export function getSelectedValue(value: any): string {
    let result = 'selected';
    value = Boolean(value);
    if (!value) {
        result = 'not_selected';
    }
    return result;
}

export function addEscapeSequenceInCsvField(ValueToEscape: string) {
    if (ValueToEscape.contains(',')) {
        return '\"' + ValueToEscape + '\"';
    }
    else {
        return ValueToEscape;
    }
}

export function countTextAreaLines(textarea: any) {
    let _buffer;
    if (_buffer == null) {
        _buffer = document.createElement('textarea');
        _buffer.style.border = 'none';
        _buffer.style.height = '0';
        _buffer.style.overflow = 'hidden';
        _buffer.style.padding = '0';
        _buffer.style.position = 'absolute';
        _buffer.style.left = '0';
        _buffer.style.top = '0';
        _buffer.style.zIndex = '-1';
        document.body.appendChild(_buffer);
    }

    const cs = window.getComputedStyle(textarea);
    // tslint:disable-next-line:radix
    const pl = parseInt(cs.paddingLeft);
    // tslint:disable-next-line:radix
    const pr = parseInt(cs.paddingRight);
    // tslint:disable-next-line:radix
    let lh = parseInt(cs.lineHeight);

    // [cs.lineHeight] may return 'normal', which means line height = font size.
    // tslint:disable-next-line:radix
    if (isNaN(lh)) { lh = parseInt(cs.fontSize); }

    // Copy content width.
    _buffer.style.width = (textarea.clientWidth - pl - pr) + 'px';

    // Copy text properties.
    _buffer.style.font = cs.font;
    _buffer.style.letterSpacing = cs.letterSpacing;
    _buffer.style.whiteSpace = cs.whiteSpace;
    _buffer.style.wordBreak = cs.wordBreak;
    _buffer.style.wordSpacing = cs.wordSpacing;
    _buffer.style.wordWrap = cs.wordWrap;

    // Copy value.
    _buffer.value = textarea.value;

    let result = Math.floor(_buffer.scrollHeight / lh);
    if (result === 0) { result = 1; }
    return result;
}
