import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { isEmpty } from 'lodash';
import { TranslationService } from 'app-services/translation.service';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CustomersService } from './customers.service';
import { CountriesHelper } from 'app-helpers/countries.helper';
import { AddCustomerComponent } from 'app-components/add-customer/add-customer.component';
import { SearchInputComponent } from 'app-components/inputs/search/search.component';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.page.html',
  styleUrls: ['./customers.page.scss'],
  providers: [DialogService]
})
export class CustomersPageComponent extends ComponentWithSubscriptions implements OnInit {

  @ViewChild('search') searchInput: SearchInputComponent;

  customers: CustomerDTO[];
  filteredCustomers: CustomerDTO[];
  dialog: DialogService;
  searchTerm: string;

  constructor(
    private customersService: CustomersService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translation: TranslationService,
    private countriesHelper: CountriesHelper,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.customersService.getAll().subscribe(customers => {
      this.customers = customers;
      this.filteredCustomers = customers;
    }));
  }

  openCreateCustomerDialog = () => {
    const ref = this.dialogService.open(AddCustomerComponent, { header: this.translation.translate('create_customer') });
    this.subscriptions.push(ref.onClose.subscribe((newCustomerDetails) => {
      this.createNewCustomer(newCustomerDetails);
    }));
  }

  createNewCustomer = (newCustomerDetails: any) => {
    if (isEmpty(newCustomerDetails)) { return; }
    this.subscriptions.push(this.customersService.createNewCustomer(newCustomerDetails).subscribe((res) => {
      if (res?.success) {
        this.customerCreatedToast();
        const customerLogoUrl = this.countriesHelper.getFlagPath(res.newCustomer.country);
        res.newCustomer.logoUrl = customerLogoUrl;
        this.customers.push(res.newCustomer);
      }
    }, (err) => {
      this.customerCreationFailedToast();
    }));
  }

  notificationReceived(notification: Notification): void {
    switch (notification.type) {
      case NotificationTypeEnum.AutocompleteSearch:
      case NotificationTypeEnum.ClearSearch: {
        this.searchTerm = notification.data;
        this.filterCustomers();
        break;
      }
    }
  }

  filterCustomers = () => {
    this.filteredCustomers = this.customers?.filter((customer) => this.applyFilters(customer));
    this.
    searchInput?.searchFinished();
  }

  applyFilters = (customer: CustomerDTO): boolean => {
    let passFilters = true;

    if (!isEmpty(this.searchTerm)) {
      const values = customer.name.toLowerCase().trim();
      passFilters = passFilters && (values.includes(this.searchTerm.toLowerCase().trim()));
    }

    return passFilters;
  }


  customerCreatedToast = () => {
    this.messageService.add({
      severity: 'success',
      key: 'toastMessage',
      detail: this.translation.translate('customer_creation_success'),
      icon: 'pi pi-info-circle',
      summary: this.translation.translate('customer_created'),
    });
  }

  customerCreationFailedToast = () => {
    this.messageService.add({
      severity: 'error',
      summary: this.translation.translate('error'),
      detail: this.translation.translate('customer_creation_err'),
      key: 'toastMessage',
    });
  }
}
