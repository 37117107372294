import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'app-services/translation.service';
import { stringFormat } from 'app-scripts/utilities/general';

@Pipe({ name: 'translate', pure: false })

export class TranslatePipe implements PipeTransform {
  static instance: TranslatePipe;
  constructor(private translationService: TranslationService) {
    return TranslatePipe.instance = TranslatePipe.instance || this;
  }
  transform(value: any, translate?: boolean, params?: any): string {
    if (value === undefined || value === null || value.isEmptyOrNullOrUndefined()) { return ''; }
    if (translate !== undefined && !translate) {
      return value;
    }
    if (params && params.length > 0) {
      const newLabel = this.translationService.translate(value);
      return stringFormat(newLabel, params);
    }
    else {
      return this.translationService.translate(value);
    }
  }
}
