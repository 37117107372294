// import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';

export const AppPermissionsTableColumnsConfiguration: TableCellConfiguration<any>[] = [
  {
    header: 'Permission Name',
    field: 'name',
    types: [TableCellType.Text],
    width: '25px',
    sortable: true,
    searchable: true,
    resizeable: true,
    styleClass: 'capitalize',
  },
  {
    header: 'Status',
    field: 'status',
    types: [TableCellType.InputSwitch],
    width: '15px',
  },
];
