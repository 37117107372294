import { UserPermissionEnum } from 'app-models/user/permission.enum';
import { UserLanguage } from './language.model';
import { RoleDTO } from 'app-models/roles/role-dto.model';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token?: string;
  language?: UserLanguage;
  permissions: UserPermissionEnum[];
  role?: RoleDTO;
  customer?: CustomerDTO;
}
