import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TableCellConfiguration } from 'app-models/table/table-cell-configuration.model';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
// import { UserActionType } from 'app-models/actions/user-action-type.enum';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { get, set } from 'lodash';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss']
})
export class TableCellComponent implements OnChanges {

  readonly TableCellType = TableCellType;

  @Input() row: any;
  @Input() config: TableCellConfiguration<any>;
  @Output() notification: EventEmitter<Notification> = new EventEmitter();
  cellValue: any;

  constructor() { }

  ngOnChanges(): void {
    if (this.config?.field) {
      this.cellValue = get(this.row, this.config.field);
    }
  }

  cellClick = (action?: any): void => {
    this.notification.emit({
      type: NotificationTypeEnum.ItemClicked,
      data: {
        row: this.row,
        field: this.config.field,
        action
      }
    });
  }

  changedCellClick = (action?: any): void => {
    set(this.row, this.config.field, this.cellValue);
    this.cellClick(action);
  }

}
