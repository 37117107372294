import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

    constructor() { }

    getImage = (image: any): any => {
        return image['default'] ? image['default'] : image;
    }
}
