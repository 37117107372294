import { Injectable } from '@angular/core';
import { UsersManager } from 'app-managers/users.manager';
import { Observable } from 'rxjs/Observable';
import { CreateNewUserResponse } from 'app-models/request/createNewUser.model'
import { UserDTO } from 'app-models/user/user-dto.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersPageService {

  constructor(private usersManager: UsersManager) { }

  getAll = (): Observable<UserDTO[]> => {
    return this.usersManager.getAll().pipe(
      map((res) => res.response?.data )
    )
  }
  createNewUser = (newUserDetails: Partial<UserDTO>): Observable<CreateNewUserResponse> => {
    return this.usersManager.createNewUser(newUserDetails).pipe(
      map((res) => res.response)
    );
  }
}
