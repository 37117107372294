import { Component, Input } from '@angular/core';
import { AppDTO } from 'app-models/app/app-dto.model';

@Component({
  selector: 'app-app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent {

  @Input() app: AppDTO;

  constructor() { }

}

