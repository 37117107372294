import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { Observable } from 'rxjs/Observable';
import { RequestResult } from 'app-models/common/requestResult.model';
import { map } from 'rxjs/operators/map';
import { RoleDTO } from 'app-models/roles/role-dto.model';


@Injectable({
  providedIn: 'root'
})
export class RolesManager {

  constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
  ) { }

  getAll = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('roles');
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: RoleDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }
}
