import { Injectable } from '@angular/core';
import { RolesManager } from 'app-managers/roles.manager';
import { RoleDTO } from 'app-models/roles/role-dto.model';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private rolesManager: RolesManager) { }

  getAll = (): Observable<RoleDTO[]> => {
    return this.rolesManager.getAll().pipe(map((res) => res.response));
  }
}
