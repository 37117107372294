import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'addCommas' })

export class AddCommasPipe implements PipeTransform {
  constructor() { }

  transform(value: string): string {
    if (value === undefined || value === '') { return value; }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
