import { Injectable } from '@angular/core';
import { RequestResult } from 'app-models/common/requestResult.model';
import { PermissionDTO } from 'app-models/permission/permission-dto.model';
import { CreateAppPermission } from 'app-models/request/createAppPermission.model';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';

@Injectable({
    providedIn: 'root'
})

export class PermissionsManager {

constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
) { }

createOne = (name: PermissionDTO['name']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('permissions');
    return this.httpRequestService.post(url, {
        name
    })
    .pipe(
        map((response: CreateAppPermission) => {
            const requestResult: RequestResult = new RequestResult();
            requestResult.response = response;
            return requestResult;
        }))
        .catch(error => {
            throw(error);
        });
    }

getAll = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('permissions');
    return this.httpRequestService.get(url)
    .pipe(
        map((response: any) => {
            const requestResult: RequestResult = new RequestResult();
            requestResult.response = response;
            return requestResult;
        }))
        .catch(error => {
            throw(error);
        });
    }

    addAppPermission = (appId: number, permissionId: number): Observable<RequestResult> => {
        const url = this.urlService.getRequestUrl('permissions', `${permissionId}`);
        return this.httpRequestService.post(url, {
            appId,
        })
        .pipe(
            map((response: any) => {
                const requestResult: RequestResult = new RequestResult();
                requestResult.response = response;
                return requestResult;
            }))
            .catch(error => {
                throw(error);
            });
        }

    removePermissionFromApp = (appId: number, permissionId: number): Observable<RequestResult> => {
        const url = this.urlService.getRequestUrl('permissions', `${permissionId}/app/${appId}`);
        return this.httpRequestService.delete(url)
        .pipe(
            map((response: any) => {
                const requestResult: RequestResult = new RequestResult();
                requestResult.response = response;
                return requestResult;
            }))
            .catch(error => {
                throw(error);
            });
        }
    }
