import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { Observable } from 'rxjs/Observable';
import { RequestResult } from 'app-models/common/requestResult.model';
import { map } from 'rxjs/operators/map';
import { AppDTO } from 'app-models/app/app-dto.model';
import * as _ from 'lodash';
import { LogoHelper } from 'app-helpers/logo.helper';
import { AppPermission } from 'app-models/app/app-permission.model';


@Injectable({
  providedIn: 'root'
})
export class AppsManager {

  constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
    private logoHelper: LogoHelper,
  ) { }

  getAll = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps');
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: AppDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          const apps = response.data;
          apps.forEach(app => {
            const appLogoUrl = this.logoHelper.generateLogoUrl(app.name);
            app.logoUrl = appLogoUrl;
          });
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  getApp = (id: AppDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${id}`);
    return this.httpRequestService.get(url)
      .pipe(
        map((app: AppDTO ) => {
          const requestResult: RequestResult = new RequestResult();
          const appLogoUrl = this.logoHelper.generateLogoUrl(`${app.name || ''}`);
          app.logoUrl = appLogoUrl;
          requestResult.response = app;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  addNewApp = (newAppDetails: Partial<AppDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps');
    return this.httpRequestService.post(url, {
      newAppDetails
    })
      .pipe(
        map((response: { success: boolean }) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw(error);
      })
  }

  updateAppDetails = (id: AppDTO['id'], newAppDetails: Partial<AppDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${id}`);
    return this.httpRequestService.post(url, {
      newAppDetails
    })
      .pipe(
        map((response: {success: boolean}) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  disableApp = (id: AppDTO['id'], status: boolean): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${id}/status`);
    return this.httpRequestService.post(url, {
      status,
    })
    .pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  updateAppTFA = (id: AppDTO['id'], disable_tfa: boolean): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${id}/tfa`);
    return this.httpRequestService.post(url, {
      disable_tfa,
    })
    .pipe(
      map((response: { success: boolean }) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.success = response.success
        return requestResult;
      })
    );
  }

  getAppPermissions = (id: AppDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${id}/permissions`);
    return this.httpRequestService.get(url)
    .pipe(
      map((data: any ) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = data;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  addAppPermission = (appPermission: AppPermission): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${appPermission.appId}/permissions`);
    const permissionId = appPermission.permissionId;
    return this.httpRequestService.post(url, {
      permissionId
    })
    .pipe(
      map((data: any ) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = data;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  removePermissionFromApp = (appPermission: AppPermission): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('apps', `${appPermission.appId}/permissions/${appPermission.permissionId}`);
    return this.httpRequestService.delete(url)
    .pipe(
      map((data: any ) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = data;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }
}
