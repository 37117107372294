import { Injectable } from '@angular/core';
import { PageConfiguration } from 'app-configurations/pages.configuration';

@Injectable()
export class PageService {
    constructor() { }

    getPageConfiguration = (componentName: string): any => {
        return PageConfiguration.find(p => p.companentName === componentName);
    }

    getAuthorization = (componentName: string): any => {
        return this.getPageConfiguration(componentName).authorizations;
    }

    getRoles = (componentName: string): any => {
        return this.getPageConfiguration(componentName).roles;
    }
}
