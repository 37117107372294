import { Text } from 'app-models/common/text.model';

export const ToastMessagesConfiguration = {
    ADD_PERM_ERROR: new Text('add_permission_err', true),
    REMOVE_PERM_ERROR: new Text('remove_permission_err', true),
    PERMISSION_CREATED: new Text('permission_created', true),
    PERMISSION_ADDED: new Text('permission_added', true),
    PERMISSOIN_REMOVED: new Text('permission_removed', true),
    CUSTOMER_UPDATED: new Text('customer_updated', true),
    CUSTOMER_UPDATED_ERROR: new Text('customer_updated_error', true),
    USER_ASSIGNED: new Text('user_assigned', true),
    USER_ASSIGNED_ERROR: new Text('user_assigned_error', true),
    USER_UNASSIGNED: new Text('user_unassigned', true),
    USER_UNASSIGNED_ERROR: new Text('user_unassigned_error', true),
    CUSTOMER_ACTIVATED: new Text('customer_activation_success', true),
    CUSTOMER_ACTIVATED_ERROR: new Text('customer_activation_error', true),
    CUSTOMER_DEACTIVATED: new Text('customer_deactivation_success', true),
    CUSTOMER_DEACTIVATED_ERROR: new Text('customer_deactivation_error', true),
    PASSWORD_CHANGED: new Text('password_changed', true),
    PASSWORD_CHANGED_ERROR: new Text('password_change_error', true),
    EXPIRY_DATE_UPDATED: new Text('expiry_date_updated', true),
    EXPIRY_DATE_ERROR: new Text('expiry_date_error', true),
    APP_TFA_ENABLED: new Text('app_tfa_enabled_success', true),
    APP_TFA_DISABLED: new Text('app_tfa_disabled_success', true),
    APP_TFA_ERROR: new Text('app_tfa_error', true),
    USER_APP_ADDED: new Text('user_app_added', true),
    USER_APP_ERROR: new Text('user_app_error', true),
    ROLE_ASSIGNED: new Text('role_assigned', true),
    ROLE_ASSIGNED_ERROR: new Text('role_assigned_error', true),
};
