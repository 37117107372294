import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-magen-spinner',
  templateUrl: './magen-spinner.component.html',
  styleUrls: ['./magen-spinner.component.scss']
})
export class MagenSpinnerComponent implements OnInit {
  @Input() size: number;
  @Input() color: boolean;
  smallIcon: boolean;
  sizePX: string;
  private readonly DEFAULT_SIZE = 70;
  constructor() { }

  ngOnInit() {
    this.size = this.size || this.DEFAULT_SIZE;
    this.smallIcon = this.size < 31;
    this.sizePX = `${this.size}px`;
  }
}
