import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safeResourceUrl' })

export class SafeResourceUrl  implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value): SafeHtml {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
