import countriesList from 'app-assets/data/countries.json';
import { Injectable } from "@angular/core";
@Injectable()
export class CountriesHelper {

  getCountries = (): any => {
    return countriesList;
  }

  getCountryByName = (name: string): any => {
    return countriesList.find((country: { name: string; }) => country.name === name);
  }

  getCountryName = (code: string): any => {
    const country = countriesList.find((coord: any) => coord.code.toLowerCase() === code.toLowerCase());
    if (country) {
      return country.name;
    }
    return false;
  }

  getCountryCode = (name: string): string => {
    const country = countriesList.find((coord: any) => coord.name.toLowerCase() === name.toLowerCase());
    if (country) {
      return country.code.toLowerCase();
    }
    return name;
  }

  getFlagPath(country: string): string {
    const countryCode = country ? this.getCountryCode(country.toLowerCase()) : 'unknown';
    return `/assets/flags/4x3/${countryCode}.svg`;
  }
}
