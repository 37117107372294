import { Injectable } from '@angular/core';
import { AppsManager } from 'app-managers/apps.manager';
import { AppDTO } from 'app-models/app/app-dto.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { AppPermission } from 'app-models/app/app-permission.model';
import { AppPermissionsResult } from 'app-models/permission/app-permissions-result';
import { sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppDetailsService {

  constructor(private appsManager: AppsManager) { }

  getApp = (id: AppDTO['id']): Observable<AppDTO> => {
    return this.appsManager.getApp(id).pipe(map((res) => res.response));
  }

  updateAppDetails = (id: AppDTO['id'], newAppDetails: Partial<AppDTO>): Observable<{ success: boolean; }> => {
    return this.appsManager.updateAppDetails(id, newAppDetails).pipe(
      map((res) => res.response)
    );
  }

  disableApp = (id: AppDTO['id'], status: boolean) => {
    return this.appsManager.disableApp(id, status);
  }

  updateAppTFA = (id: AppDTO['id'], disableTFA: boolean) => {
    return this.appsManager.updateAppTFA(id, disableTFA);
  }

  getAppPermissions = (id: AppDTO['id']): Observable<AppPermissionsResult> => {
    return this.appsManager.getAppPermissions(id).pipe(map((res: any) => {
      res.response.appPermissions = sortBy(res.response.appPermissions, ['name']);
      return res.response;
    }));
  }
  addAppPermission = (appPermission: AppPermission) => {
    return this.appsManager.addAppPermission(appPermission);
  }
  removePermissionFromApp = (appPermission: AppPermission) => {
    return this.appsManager.removePermissionFromApp(appPermission);
  }
}
