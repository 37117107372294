import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  LoginPageComponent, UnauthorizedPageComponent
} from 'app-pages/pages.module';

import { MainLayoutComponent } from 'app-layouts/layouts.module';
import { UsersPageComponent } from 'app-pages/users/users.page';
import { AppsPageComponent } from 'app-pages/apps/apps-page/apps.page';
import { UserDetailsComponent } from 'views/user-details/user-details.component';
import { AppDetailsComponent } from 'app-views/app-details/app-details.component';
import { PermissionsPageComponent } from 'app-pages/permissions-page/permissions.page';
import { CustomersPageComponent } from 'app-pages/customers/customers.page';
import { CustomerDetailsComponent } from 'app-views/customer-details/customer-details.component';
import { Roles } from 'app-models/roles/roles.enum';
import { RoleGuard } from 'guards/role.guard';
import { PermissionGuard } from 'guards/permission.guard';
import { UserPermissionEnum } from 'app-models/user/permission.enum';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'error', component: UnauthorizedPageComponent },
  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'customers', component: CustomersPageComponent,
        canActivate: [PermissionGuard],
        data: {
          allowedPermissions: [UserPermissionEnum.Customers]
        }
      },
      {
        path: 'customers/:id', component: CustomerDetailsComponent,
      },
      {
        path: 'users', component: UsersPageComponent,
        canActivate: [PermissionGuard],
        data: {
          allowedPermissions: [UserPermissionEnum.Users]
        }
      },
      {
        path: 'users/:id', component: UserDetailsComponent,
      },
      {
        path: 'apps', component: AppsPageComponent,
        canActivate: [PermissionGuard],
        data: {
          allowedPermissions: [UserPermissionEnum.Apps]
        }
      },
      {
        path: 'apps/:id', component: AppDetailsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: [Roles.Admin]
        }
      },
      {
        path: 'permissions', component: PermissionsPageComponent,
        canActivate: [PermissionGuard],
        data: {
          allowedPermissions: [UserPermissionEnum.Permissions]
        }
      },
      { path: '**', redirectTo: '/users', pathMatch: 'full' },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
