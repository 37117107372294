import { Injectable } from '@angular/core';
import { AppsManager } from 'app-managers/apps.manager';
import { PermissionsManager } from 'app-managers/permissions.manager';
import { AppPermission } from 'app-models/app/app-permission.model';
import { PermissionDTO } from 'app-models/permission/permission-dto.model';
import { CreateAppPermission } from 'app-models/request/createAppPermission.model';
import { AppsPermissionsResult } from 'app-models/permission/apps-permissions-result';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { isEmpty, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PermissionsPageService {

  constructor(
    private permissionsManager: PermissionsManager,
    private appsManager: AppsManager
  ) { }

  createPermission = (name: PermissionDTO['name']): Observable<CreateAppPermission> => {
    return this.permissionsManager.createOne(name).pipe(
      map((res) => res.response)
    );
  }

  getAppsPermissions = (): Observable<AppsPermissionsResult> => {
    return this.permissionsManager.getAll().pipe(
      map((res) => {
        if (!isEmpty(res.response?.data)) {
          res.response.data.permissions = sortBy(res.response?.data.permissions, ['name']);
        }
        return res.response?.data;
      })
    );
  }

  updatePermission = (appPermission: AppPermission, status: boolean) => {
    if (status) {
      return this.appsManager.addAppPermission(appPermission);
    }
    else {
      return this.appsManager.removePermissionFromApp(appPermission);
    }
  }
}
