import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { Observable } from 'rxjs/Observable';
import { RequestResult } from 'app-models/common/requestResult.model';
import { map } from 'rxjs/operators/map';
import { UserDTO } from 'app-models/user/user-dto.model';
import * as _ from 'lodash';
import { LogoHelper } from 'app-helpers/logo.helper';
import { AppPermissionsResult } from 'app-models/permission/app-permissions-result';

@Injectable({
  providedIn: 'root'
})
export class UsersManager {

  constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
    private logoHelper: LogoHelper,
  ) { }

  getAll = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users');
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: UserDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          const users = response.data;
          users.forEach(user => {
            const userLogoUrl = this.logoHelper.generateLogoUrl(`${user.first_name || ''}+${user.last_name || ''}`);
            user.logoUrl = userLogoUrl;
          });
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  getUser = (id: UserDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}`);
    return this.httpRequestService.get(url)
      .pipe(
        map((user: UserDTO ) => {
          const requestResult: RequestResult = new RequestResult();
          const userLogoUrl = this.logoHelper.generateLogoUrl(`${user.first_name || ''}+${user.last_name || ''}`);
          user.logoUrl = userLogoUrl;
          requestResult.response = user;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  generatePassword = (id: UserDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/password`);
    return this.httpRequestService.post(url).pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );

  }

  changePassword = (newPassword: string): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', 'me/change-password');
    return this.httpRequestService.post(url, {
      newPassword
    }).pipe(
      map((response: { data: { success: boolean, message?: string} }) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response.data;
        return requestResult;
      })
    );
  }

  disableTFA = (id: UserDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/tfa`);
    return this.httpRequestService.delete(url).pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  updateUserDetails = (id: UserDTO['id'], newUserDetails: Partial<UserDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}`);
    return this.httpRequestService.post(url, {
      newUserDetails
    })
      .pipe(
        map((response: {success: boolean}) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  deleteUser = (id: UserDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}`);
    return this.httpRequestService.delete(url).pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  createNewUser = (newUserDetails: Partial<UserDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', 'register');
    return this.httpRequestService.post(url, {
      newUserDetails
    })
      .pipe(
        map((response: { success: boolean, password: string }) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw(error);
      });
  }

  getPermissions = (id: UserDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/permissions`);
    return this.httpRequestService.get(url)
    .pipe(
      map((response: { data: AppPermissionsResult[] }) => {
      const requestResult: RequestResult = new RequestResult();
      requestResult.response = response;
      return requestResult;
      }));
  }

  addPermissions = (id: UserDTO['id'], permissions: { permissionId: number, applicationId: number }[]): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/permissions`);
    return this.httpRequestService.post(url, {
      id,
      permissions
    })
    .pipe(
      map((response: {success: boolean}) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  deletePermission = (id: UserDTO['id'], permissionId: number, applicationId: number): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/permissions/${permissionId}/app/${applicationId}`);
    return this.httpRequestService.delete(url)
    .pipe(
      map((response: {success: boolean}) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  disableUser = (id: UserDTO['id'], status: boolean): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/status`);
    return this.httpRequestService.post(url, {
      status,
    })
    .pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  logout = () => {
    const url = this.urlService.getRequestUrl('users', `logout`);
    this.httpRequestService.post(url).subscribe();
  }


  saveApplications = (id: UserDTO['id'], applicationIds: number[]): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl('users', `${id}/applications`);
    return this.httpRequestService.post(url, {
      applicationIds
    })
      .pipe(
        map((response: {success: boolean}) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }
}
