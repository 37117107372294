import { AfterViewInit, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from 'app-services/user.service';
import { isEmpty, toArray } from 'lodash';
import { UserPermissionEnum } from 'app-models/user/permission.enum';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[allowedPermissions]'
})
export class AllowedPermissionsDirective implements OnInit, AfterViewInit {
  private _allowedPermissions: UserPermissionEnum[];
  @Input() set allowedPermissions(permissions: UserPermissionEnum[]) {
    this._allowedPermissions = permissions;
  }

  private _disabled: boolean;
  @Input() set allowedPermissionsDisabled(value: boolean) {
    this._disabled = value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    const allowed = this.isAllowed();
    if (!allowed && !this._disabled) {
      // Remove element from DOM
      this.viewContainerRef.clear();
    } else {
      // appends the ref element to DOM
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  public ngAfterViewInit(): void {
    const allowed = this.isAllowed();
    if (!allowed && this._disabled) {
      const siblingElement = this.templateRef.elementRef.nativeElement.previousElementSibling;
      siblingElement.disabled = true;
      siblingElement.style.cursor = 'not-allowed';
      const children = siblingElement.getElementsByTagName('*');
      toArray(children).forEach((child) => {
        child.style.cursor = 'unset';
        child.disabled = true;
      });
      this.handleClickEvents();
    }
  }

  private isAllowed = (): boolean => {
    return isEmpty(this._allowedPermissions) || this.userService.hasPermission(this._allowedPermissions);
  }

  private handleClickEvents() {
    const parentNode = this.templateRef.elementRef.nativeElement.previousElementSibling;
    parentNode.addEventListener('click', (event) => {
      event.stopPropagation();
    }, { capture: true });
  }
}
