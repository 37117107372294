import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppDTO } from 'app-models/app/app-dto.model';
import { AppDetailsService } from './app-details.service';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { ActivatedRoute, Router } from '@angular/router';
import { share } from 'rxjs/operators/share';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppPermissionsTableConfiguration } from './configurations/app-permissions-table.configruation';
import { AppPermissionsTableColumnsConfiguration } from './configurations/app-permissions-table-columns.configuration';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { PermissionsPageService } from 'app-pages/permissions-page/permissions.service';
import { Text } from 'app-models/common/text.model';
import { TranslationService } from 'app-services/translation.service';
import { ToastMessagesConfiguration } from 'app-configurations/toast-messages.cofiguration';
import { LogoHelper } from 'app-helpers/logo.helper';
import { PermissionDTO } from 'app-models/permission/permission-dto.model';
import { Severity } from 'app-models/toast/severity.enum';
import { sortBy } from 'lodash';

@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.scss']
})
export class AppDetailsComponent extends ComponentWithSubscriptions implements OnInit {

  app: AppDTO;
  detailsForm: FormGroup;
  newPermissionForm: FormGroup;
  appDisabled: boolean;
  appDetailsChanged: boolean;
  activeFragment = this.activatedRoute.fragment.pipe(share());

  dialog: ConfirmationService;
  permissions: PermissionDTO[];
  appPermissionsTable: any = {
    config: AppPermissionsTableConfiguration,
    columns: AppPermissionsTableColumnsConfiguration
  };

  constructor(
    private appDetailsService: AppDetailsService,
    private permissionsService: PermissionsPageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private translation: TranslationService,
    private logoHelper: LogoHelper,
  ) {
    super();
  }

  ngOnInit(): void {
    this.detailsForm = new FormGroup({
      name: new FormControl('', Validators.required)
    });
    this.newPermissionForm = new FormGroup({
      name: new FormControl('', Validators.required)
    });
    this.app = undefined;
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.subscriptions.push(this.appDetailsService.getApp(id as unknown as number).subscribe((app: AppDTO) => {
      if (app) {
        this.app = app;
        const { name } = app;
        this.detailsForm.setValue({ name });
        this.appDisabled = app.disabled;
      }
      else {
        this.router.navigate(['/error']);
      }
    }));

    this.subscriptions.push(this.detailsForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(_.isEqual))
      .subscribe(newValue => {
        const { name } = this.app;
        this.appDetailsChanged = !_.isEqual(newValue, { name });
      }));

    this.subscriptions.push(this.appDetailsService.getAppPermissions(parseInt(this.activatedRoute.snapshot.paramMap.get('id')))
      .subscribe((permissions) => {
        permissions.appPermissions.forEach((permission) => {
          permission.name = permission.name.toDisplayText();
        });
        this.appPermissionsTable.data = permissions.appPermissions;
        this.permissions = permissions.permissionsMap;
      }));
  }

  saveApp = () => {
    const newAppDetails: Partial<AppDTO> = this.detailsForm.value;
    this.subscriptions.push(this.appDetailsService.updateAppDetails(this.app.id, newAppDetails).subscribe((res) => {
      if (res?.success) {
        Object.keys(newAppDetails).forEach(key => this.app[key] = newAppDetails[key]);
        this.app.logoUrl = this.logoHelper.generateLogoUrl(`${this.app.name || ''}`);
        setTimeout(() => {
          this.appDetailsChanged = false;
        }, 10);
      }
    }));
  }

  cancelEditApp = () => {
    const { name } = this.app;
    this.detailsForm.setValue({ name });
    this.appDetailsChanged = false;
  }

  openActivateAppDialog = () => {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('app_activation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('activate'),
      accept: () => {
        this.activateApp();
      },
    });
  }

  activateApp = () => {
    this.subscriptions.push(this.appDetailsService.disableApp(this.app.id, false).subscribe((res) => {
      if (res) {
        this.appDisabled = false;
      }
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: this.translation.translate('app_activation_success'),
        icon: 'pi pi-info-circle',
        header: this.translation.translate('app_activated'),
        rejectLabel: this.translation.translate('dismiss'),
        acceptVisible: false,
      });
    }));
  }

  openDeactivateAppDialog = () => {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('app_deactivation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('deactivate'),
      accept: () => {
        this.deactivateApp();
      },
    });
  }

  openTFADialog = ( ) => {
    const label = this.app.disable_tfa ? 'enable': 'disable';
    const message = this.app.disable_tfa ? 'app_tfa_activation_confirm': 'app_tfa_deactivation_confirm';
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate(message),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate(label),
      accept: () => {
        this.updateAppTFA();
      },
    });
  }

  updateAppTFA = () => {
    this.subscriptions.push(this.appDetailsService.updateAppTFA(this.app.id, !this.app.disable_tfa).subscribe((res) => {
      if (!res.success) {
        this.addToast(ToastMessagesConfiguration.APP_TFA_ERROR, Severity.Error);
      } else {
        if (this.app.disable_tfa) {
          this.addToast(ToastMessagesConfiguration.APP_TFA_ENABLED, Severity.Success);
        } else {
          this.addToast(ToastMessagesConfiguration.APP_TFA_DISABLED, Severity.Success);
        }
        this.app.disable_tfa = !this.app.disable_tfa;
      }
    }));
  }

  deactivateApp = () => {
    this.subscriptions.push(this.appDetailsService.disableApp(this.app.id, true).subscribe((res) => {
      if (res) {
        this.appDisabled = true;
      }
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: this.translation.translate('app_deactivation_success'),
        icon: 'pi pi-info-circle',
        header: this.translation.translate('app_disabled'),
        rejectLabel: this.translation.translate('dismiss'),
        acceptVisible: false,
      });
    }));
  }

  notificationReceived = (notification: Notification): void => {
    switch (notification.type) {
      case NotificationTypeEnum.ItemClicked:
        const data = notification.data;
        const permissionName = (data?.row?.name as string).toSnakeCase();
        if (data.row.status === true) {
          this.subscriptions.push(this.appDetailsService.addAppPermission(
            { appId: this.app.id, permissionId: this.permissions[permissionName] }).subscribe((res) => {
              if (!res) {
                this.addToast(ToastMessagesConfiguration.ADD_PERM_ERROR, Severity.Error);
              } else {
                this.addToast(ToastMessagesConfiguration.PERMISSION_ADDED, Severity.Success);
              }
            }));
        } else {
          this.subscriptions.push(this.appDetailsService.removePermissionFromApp(
            { appId: this.app.id, permissionId: this.permissions[permissionName] }).subscribe((res) => {
              if (!res) {
                this.addToast(ToastMessagesConfiguration.REMOVE_PERM_ERROR, Severity.Error);
              } else {
                this.addToast(ToastMessagesConfiguration.PERMISSOIN_REMOVED, Severity.Success);
              }
            }));
        }
    }
  }

  createAppPermission = () => {
    const displayName = this.newPermissionForm.value.name;
    const permissionKey = displayName.toSnakeCase();
    this.subscriptions.push(this.permissionsService.createPermission(permissionKey)
      .subscribe((newPermissionObj) => {
        if (newPermissionObj.success) {
          const permissionId = newPermissionObj.permission.id;
          this.subscriptions.push(this.appDetailsService.addAppPermission(
            { appId: this.app.id, permissionId }).subscribe((res) => {
              if (!res) {
                this.addToast(ToastMessagesConfiguration.ADD_PERM_ERROR, Severity.Error);
              } else {
                this.addToast(ToastMessagesConfiguration.PERMISSION_ADDED, Severity.Success);
                const newPermission = {
                  name: newPermissionObj.permission.name.toDisplayText(),
                  status: true
                };
                this.appPermissionsTable.data.push(newPermission);
                this.appPermissionsTable.data = sortBy(this.appPermissionsTable.data, ['name']);
                this.newPermissionForm.reset();
              }
            }));
        } else { this.addToast(new Text(newPermissionObj.message, false), Severity.Error); }
      }));
  }

  addToast(message: Text, severity: string) {
    const detail = message.needsTranslation ? this.translation.translate(message.text) : message.text;
    this.messageService.add({
      severity,
      summary: this.translation.translate(severity),
      detail,
      key: 'toastMessage',
    });
  }
}
