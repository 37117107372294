import { Component, OnInit, ViewChild } from '@angular/core';
import { UserDTO } from 'app-models/user/user-dto.model';
import { Notification } from 'app-models/notification/notification.model';
import { NotificationTypeEnum } from 'app-models/notification/type.enum';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { share } from 'rxjs/operators/share';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableComponent } from 'app-components/tables/table/table.component';
import { UserPermissionsTableConfiguration } from './configurations/user-permissions-table.configuration';
import { UserPermissionsTableColumnsConfiguration } from './configurations/user-permissions-table-columns.configuration';
import { LogoHelper } from 'app-helpers/logo.helper';
import { TranslationService } from 'app-services/translation.service';
import { TableCellType } from 'app-models/table/table-cell-type.enum';
import { copyToClipboard, deepCloneObject } from 'app-scripts/utilities/general';
import { RolesService } from 'app-services/roles.service';
import { RoleDTO } from 'app-models/roles/role-dto.model';
import { Text } from 'app-models/common/text.model';
import { ToastMessagesConfiguration } from 'app-configurations/toast-messages.cofiguration';
import { Severity } from 'app-models/toast/severity.enum';
import { Roles } from 'app-models/roles/roles.enum';
import { AppDTO } from 'app-models/app/app-dto.model';
import { AppsPageService } from 'app-pages/apps/apps-page/apps-page.service';
import { UserService } from 'app-services/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent extends ComponentWithSubscriptions implements OnInit {

  readonly Roles = Roles;
  user: UserDTO;
  activeFragment = this.activatedRoute.fragment.pipe(share());
  userTFAState: boolean;
  userDisabled: boolean;
  detailsForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
  });
  userDetailsChanged: boolean;
  dialog: ConfirmationService;
  @ViewChild('table') table: TableComponent;
  permissionsTable: any;
  appMap: any;
  roles: any;
  selectedRole: {id: number, name: string };
  appsDropdownData: Partial<AppDTO>[];
  selectedApps: Partial<AppDTO>[];
  selectedAppsTooltip: string;
  lastLogin: string;


  constructor(
    private userDetailsService: UserDetailsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private logoHelper: LogoHelper,
    private translation: TranslationService,
    private rolesService: RolesService,
    private messageService: MessageService,
    private appsService: AppsPageService,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = undefined;
    this.subscriptions.push(this.rolesService.getAll().subscribe((res) => {
      if (res) {
        this.roles = res.map((role) => {
          return {
            id: role.id,
            name: role.name.upperCaseFirstLetter(),
          }
        });
      }
    }));
    this.appsDropdownData = [];
    this.permissionsTable = {
      config: UserPermissionsTableConfiguration,
      columns: deepCloneObject(UserPermissionsTableColumnsConfiguration)
    };

    if (this.userService.hasRole(Roles.Admin)) {
      this.subscriptions.push(this.appsService.getAll().subscribe((res) => {
        if (res) {
          this.appsDropdownData = res.map((app) => {
            return {
              id: app.id,
              name: app.name,
            }
          });
        }
      }));
    }

    this.subscriptions.push(this.userDetailsService.getPermissions(this.activatedRoute.snapshot.paramMap.get('id'))
    .subscribe((result) => {
      result.permissions.forEach((permission) => {
        permission.name = permission.name.toDisplayText();
      });
      this.permissionsTable.data = result.permissions;
      this.appMap = result.appsMap;
      Object.keys(this.appMap).forEach((app) => {
        this.permissionsTable.columns.push({
            header: `${app}`,
            field: `${app}`,
            resizeable: true,
            types: [TableCellType.Checkbox],
        });
      });
    }));

    this.subscriptions.push(this.userDetailsService.getUser(this.activatedRoute.snapshot.paramMap.get('id')).subscribe((user: UserDTO) => {
      if (user) {
        this.user = user;
        const { first_name, last_name, role, applications, last_login } = user;
        this.lastLogin = !!last_login ? new Date(last_login).toLocaleString('en-IL') : undefined;
        this.detailsForm.setValue({ first_name, last_name });
        this.userTFAState = this.user.tfa;
        this.userDisabled = this.user.disabled;
        if (role) {
          this.selectedRole = { id: role.id, name: role.name.upperCaseFirstLetter() };
        }
        this.selectedApps = applications;
        this.updateAppsTooltip();
        if (this.user.rateLimiterBlocked) {
          this.openUserLockedDialog();
        }
      }
      else {
        this.router.navigate(['/error']);
      }
    }));
    this.subscriptions.push(this.detailsForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged(isEqual))
      .subscribe(newValue => {
        const { first_name, last_name } = this.user;
        this.userDetailsChanged = !isEqual(newValue, { first_name, last_name });
      }));
  }

  saveUser = () => {
    const newUserDetails: Partial<UserDTO> = this.detailsForm.value;
    this.subscriptions.push(this.userDetailsService.updateUserDetails(this.user.id, newUserDetails).subscribe((res) => {
      if (res?.success) {
        Object.keys(newUserDetails).forEach(key => this.user[key] = newUserDetails[key]);
        this.user.logoUrl = this.logoHelper.generateLogoUrl(`${this.user.first_name || ''}+${this.user.last_name || ''}`);
        setTimeout(() => {
          this.userDetailsChanged = false;
        }, 10);
      }
    }));
  }

  cancelEditUser = () => {
    const { first_name, last_name } = this.user;
    this.detailsForm.setValue({ first_name, last_name });
    this.userDetailsChanged = false;
  }

  openGeneratePasswordDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('gen_new_password_confirmation'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('generate'),
      accept: () => {
        this.generateDialog();
      },
    });
  }

  openDeleteUserDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: 'Are you sure you want to delete this user?',
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmation',
      acceptLabel: 'Yes, delete',
      accept: () => {
        this.subscriptions.push(this.userDetailsService.deleteUser(this.user.id).subscribe((res) => {
          if (res) {
            this.router.navigate(['']);
          }
        }));
      },
    });
  }

  openDisableTFADialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('disable_tfa_confirmation'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('disable'),
      accept: () => {
        this.disableDialog();
      },
    });
  }

  disableDialog() {
    this.subscriptions.push(this.userDetailsService.disableTFA(this.user.id).subscribe((res) => {
      if (res) {
        this.userTFAState = false;
      }
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: this.translation.translate('disable_tfa_success'),
        icon: 'pi pi-info-circle',
        header: this.translation.translate('tfa_off'),
        rejectLabel: this.translation.translate('dismiss'),
        acceptVisible: false,
      });
    }));
  }

  generateDialog() {
    this.subscriptions.push(this.userDetailsService.generatePassword(this.user.id).subscribe((res) => {
      const password = res.password;
      this.user.rateLimiterBlocked = false;
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: `${this.translation.translate('new_generated_password')} <b>${password}</b> ${this.translation.translate('user_to_change_password')}`,
        icon: 'pi pi-info-circle',
        header: this.translation.translate('password_generated'),
        rejectLabel: this.translation.translate('dismiss'),
        accept: () => copyToClipboard(password),
        acceptLabel: this.translation.translate('copy_clipboard'),
      acceptIcon: 'fas fa-clipboard',
      });
    }));
  }

  openDeactivateUserDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('user_deactivation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('deactivate'),
      accept: () => {
        this.deactivateUser();
      },
    });
  }

  deactivateUser() {
    this.subscriptions.push(this.userDetailsService.disableUser(this.user.id, true).subscribe((res) => {
      if (res) {
        this.userDisabled = true;
      }
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: this.translation.translate('user_deactivation_success'),
        icon: 'pi pi-info-circle',
        header: this.translation.translate('user_disabled'),
        rejectLabel: this.translation.translate('dismiss'),
        acceptVisible: false,
      });
    }));
  }

  openActivateUserDialog() {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('user_activation_confirm'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('activate'),
      accept: () => {
        this.activateUser();
      },
    });
  }

  activateUser() {
    this.subscriptions.push(this.userDetailsService.disableUser(this.user.id, false).subscribe((res) => {
      if (res) {
        this.userDisabled = false;
      }
      this.confirmationService.confirm({
        key: 'messageDialog',
        message: this.translation.translate('user_activation_success'),
        icon: 'pi pi-info-circle',
        header: this.translation.translate('user_activated'),
        rejectLabel: this.translation.translate('dismiss'),
        acceptVisible: false,
      });
    }));
  }

  saveUserRole = () => {
    this.selectedRole = { id: this.selectedRole.id, name: this.selectedRole.name.toLowerCase() };
    if (this.selectedRole && !isEqual(this.selectedRole, this.user.role)) {
      const newUserDetails = { role: this.selectedRole as RoleDTO };
      this.subscriptions.push(this.userDetailsService.updateUserDetails(this.user.id, newUserDetails).subscribe((res) => {
        if (res?.success) {
          this.user.role = { id: this.selectedRole.id, name: this.selectedRole.name.toLowerCase() as Roles };
          this.addToast(ToastMessagesConfiguration.ROLE_ASSIGNED, Severity.Success);
        }
      }));
    } else {
      this.addToast(ToastMessagesConfiguration.ROLE_ASSIGNED_ERROR, Severity.Error);
    }
    this.selectedRole = { id: this.selectedRole.id, name: this.selectedRole.name.upperCaseFirstLetter() };
  }

  saveApplications = () => {
    const applicationIds = this.selectedApps.map((app) => {
      return app.id;
    }) || [];
    this.subscriptions.push(this.userDetailsService.saveApplications(this.user.id, applicationIds).subscribe((res) => {
      if (res.success) {
        this.user.applications = this.selectedApps;
        this.addToast(ToastMessagesConfiguration.USER_APP_ADDED, Severity.Success);
      } else {
        this.addToast(ToastMessagesConfiguration.USER_APP_ERROR, Severity.Error);
      }
    }));
  }

  updateAppsTooltip = () => {
    this.selectedAppsTooltip = this.selectedApps?.map((app) => app.name).join(', ');
  }

  openUserLockedDialog = () => {
    this.dialog = this.confirmationService.confirm({
      key: 'confirmDialog',
      message: this.translation.translate('user_locked'),
      icon: 'pi pi-exclamation-triangle',
      header: this.translation.translate('confirmation'),
      acceptLabel: this.translation.translate('generate'),
      rejectLabel: this.translation.translate('dismiss'),
      accept: () => {
        this.generateDialog();
      },
    });
  }

  notificationReceived(notification: Notification): void {
    switch (notification.type) {
      case NotificationTypeEnum.ItemClicked:
        const data = notification.data;
        const applicationId = this.appMap[data.field];
        const permissionId = data.row.id;
        const status = data.row[data.field];
        this.handlePermissionChange(permissionId, applicationId, status);
        break;
    }
  }

  handlePermissionChange = (permissionId: number, applicationId: number, status: boolean) => {
    if (!status) {
      this.deletePermission(permissionId, applicationId);
    } else {
      const authApp = this.appMap['auth'];
      const enabledPermission = this.permissionsTable.data.find((permission) => permission.name === 'enabled').id;
      if (status && applicationId === authApp && permissionId === enabledPermission) {
        const permissions = [];
        permissions.push({ permissionId, applicationId });
        const usersPermissionId = this.permissionsTable.data.find((permission) => permission.name === 'users').id;
        permissions.push({ permissionId: usersPermissionId, applicationId });
        const customersPermissionId = this.permissionsTable.data.find((permission) => permission.name === 'customers').id;
        permissions.push({ permissionId: customersPermissionId, applicationId });
        this.addPermissions(permissions);
      } else {
        this.addPermissions([{ permissionId, applicationId }]);
      }
    }
  }

  addPermissions = (permissions: { permissionId: number, applicationId: number }[]) => {
    this.subscriptions.push(this.userDetailsService.addPermissions(this.user.id, permissions).subscribe((res) => {
      if (res) {
        this.addToast(ToastMessagesConfiguration.PERMISSION_ADDED, Severity.Success);
      } else {
        this.addToast(ToastMessagesConfiguration.PERMISSION_ADDED, Severity.Error);
      }
    }));
  }

  deletePermission = (permissionId: number, applicationId: number) => {
    this.subscriptions.push(this.userDetailsService.deletePermission(this.user.id, permissionId, applicationId).subscribe((res) => {
      if (res) {
        this.addToast(ToastMessagesConfiguration.PERMISSOIN_REMOVED, Severity.Success);
      } else {
        this.addToast(ToastMessagesConfiguration.PERMISSOIN_REMOVED, Severity.Error);
      }
    }));
  }

  addToast(message: Text, severity: string) {
    const detail = message.needsTranslation ? this.translation.translate(message.text) : message.text;
    this.messageService.add({
      severity,
      summary: this.translation.translate(severity),
      detail,
      key: 'toastMessage',
    });
  }
}
