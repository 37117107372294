import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppDTO } from 'app-models/app/app-dto.model';

@Component({
  selector: 'app-add-app',
  templateUrl: './add-app.component.html',
  styleUrls: ['./add-app.component.scss']
})
export class AddAppComponent implements OnInit {

  moveToAppPage: boolean;
  detailsForm: FormGroup;

  constructor(
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.moveToAppPage = true;
    this.detailsForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  saveApp = () => {
    const newAppDetails: Partial<AppDTO> = this.detailsForm.value;
    this.ref.close({ 
      newAppDetails, 
      moveToAppPage: this.moveToAppPage} );
  }

}
