interface String {
    contains(obj: String): boolean;
    replaceAll(str1: string, str2: string, ignore?: boolean): string;
    lowerCaseFirstLetter(): string;
    upperCaseFirstLetter(): string;
    isEmptyOrNullOrUndefined(): boolean;
    isWhiteSpace(): boolean;
    toNumber(): number;
    indexesOf(substring: String): number[];
    getFirstNumberInValue(): number;
    getFirstLetter(): string;
    getLastLetter(): string;
    toSnakeCase(): string;
    toDisplayText(): string;
}

interface Array<T> {
    propertyValueIsTrue(propertyName: string): boolean;
    diff(a2: any[]): any[];
    insert(ndex: number, item: any): void;
}

String.prototype.contains = function (it: string) { return this.indexOf(it) !== -1; };

String.prototype.getFirstNumberInValue = function () {
    // tslint:disable-next-line:max-line-length
    return this.match(/\d+/)[0].toNumber();
};

String.prototype.replaceAll = function (str1, str2, ignore) {
    // tslint:disable-next-line:max-line-length
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, '\\$&'), (ignore ? 'gi' : 'g')), (typeof (str2) === 'string') ? str2.replace(/\$/g, '$$$$') : str2);
};

String.prototype.lowerCaseFirstLetter = function () {
    return this.charAt(0).toLowerCase() + this.slice(1);
};

String.prototype.upperCaseFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.getFirstLetter = function () {
    return this.charAt(0);
};

String.prototype.getLastLetter = function () {
    return this.charAt(this.length - 1);
};

String.prototype.isWhiteSpace = function () {
    const value = this;
    if (!/\S/.test(value)) {
        return true;
    }
    return false;
};

String.prototype.toNumber = function () {
    return Number(this);
};

String.prototype.isEmptyOrNullOrUndefined = function () {
    let result = false;
    const value = this.toString();
    if (value === '' || value === undefined || value === null) {
        result = true;
    }

    return result;
};

String.prototype.indexesOf = function (substring) {
    const result = [];
    for (let i = this.length; i >= 0; i--) {
        if (this[i] === substring) {
            result.push(i);
        }
    }
    return result;
};

String.prototype.toDisplayText = function (): string {
    return this.trim().replaceAll('_and_', ' & ').replaceAll('_', ' ');
};

String.prototype.toSnakeCase = function (): string {
    return this.trim().toLowerCase().replaceAll('&', '_and_').replaceAll(' ', '_');
};

Array.prototype.insert = function (index: number, item: any) {
    this.splice(index, 0, item);
};

Array.prototype.diff = function (compare) {
    return this.filter(function (elem: any) { return !compare.includes(elem); });
};

Array.prototype.propertyValueIsTrue = function (propertyName) {
    const array = this;
    let allSelected = true;
    array.forEach((item: any) => {
        if (!item[propertyName]) {
            allSelected = false;
            return;
        }
    });
    return allSelected;
};
