import { Component, Input,  } from '@angular/core';

@Component({
  selector: 'app-page-navigator',
  templateUrl: './page-navigator.component.html',
  styleUrls: ['./page-navigator.component.scss']
})
export class PageNavigatorComponent {
  @Input() navigationRoute: {
    route: string;
    icon?: string;
    text?: string;
  };

  constructor() { }
}
