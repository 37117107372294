export enum NotificationTypeEnum {
  ClearSearch = 'clear_search',
  RemoveSelection = 'remove_selection',
  SortChanged = 'sort_changed',
  Edit = 'edit',
  Cancel = 'cancel',
  ValueChanged = 'crawler_changed',
  ItemsLoaded = 'items_loaded',
  GetMoreData = 'get_more_data',
  Delete = 'delete',
  ItemSelected = 'item_selected',
  Open = 'open',
  Excute_Search = 'excute_search',
  Select_One = 'select_one',
  Item_Changed = 'item_changed',
  CreateItem = 'create_item',
  ItemClicked = 'item_clicked',
  Terminate = 'terminate',
  Ready = 'ready',
  Close = 'close',
  Save = 'save',
  AutocompleteSearch = 'autocomplete_search',
  Download = 'download',
  FileChanged = 'file_changed',
  ErrorOccured = 'error_occured',
  Refresh = 'refresh',
  ChangeETLState = 'change_etl_state',
  DrawMode = 'DrawMode',
  ChangedBounds = 'ChangedBounds',
  Execute = 'execute',
  SaveEnrichment = 'save_enrichment',
  RemoveItem = 'remove_item',
  RemoveAll = 'remove_all',
  OpenResourceEnrichment = 'resource',
  OpenEntityEnrichment = 'entity',
}
