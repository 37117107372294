import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { AppDTO } from 'app-models/app/app-dto.model';
import { AppsPageService } from './apps-page.service';
import { DialogService } from 'primeng/dynamicdialog';
import { AddAppComponent } from 'app-components/add-app/add-app.component';
import { LogoHelper } from 'app-helpers/logo.helper';
import { MessageService } from 'primeng/api';
import { TranslationService } from 'app-services/translation.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-apps-page',
  templateUrl: './apps.page.html',
  styleUrls: ['./apps.page.scss'],
  providers: [DialogService]
})
export class AppsPageComponent extends ComponentWithSubscriptions implements OnInit {

  apps: AppDTO[];

  constructor(
    private appsPageService: AppsPageService,
    private dialogService: DialogService,
    private logoHelper: LogoHelper,
    private messageService: MessageService,
    private router: Router,
    private translation: TranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.appsPageService.getAll().subscribe(apps => {
      this.apps = apps;
    }));
  }

  openAddAppDialog() {
    const ref = this.dialogService.open(AddAppComponent, { header: this.translation.translate('add_app') });
    this.subscriptions.push(ref.onClose.subscribe(({ newAppDetails, moveToAppPage }) => {
      this.addNewApp(newAppDetails, moveToAppPage);
    }));
  }

  addNewApp = (newAppDetails: any, moveToAppPage: boolean) => {
    if (isEmpty(newAppDetails)) { return; }
    this.subscriptions.push(this.appsPageService.addNewApp(newAppDetails).subscribe((res) => {
      if (res?.success) {
        this.appCreatedToast();
        const appLogoUrl = this.logoHelper.generateLogoUrl(res.newApp.name || '');
        res.newApp.logoUrl = appLogoUrl;
        this.apps.push(res.newApp);
        if (moveToAppPage) {
          this.router.navigate([`/apps/${res.newApp.id}`]);
        }
      }
    }, (err) => {
      this.appCreationFailedToast();
    }));
  }

  appCreatedToast() {
    this.messageService.add({
      severity: 'success',
      summary: this.translation.translate('success'),
      detail: this.translation.translate('app_creation_success'),
      key: 'toastMessage',
    });
  }

  appCreationFailedToast() {
    this.messageService.add({
      severity: 'error',
      summary: this.translation.translate('error'),
      detail: this.translation.translate('app_creation_err'),
      key: 'toastMessage',
    });
  }
}
