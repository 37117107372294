import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoHelper {

  constructor() { }

  generateLogoUrl = (name: string): string => {
    const logoUrl = `https://ui-avatars.com/api/?background=random&name=${name || ''}`;
    return logoUrl;
  }
}
