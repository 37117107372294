import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LoginManager } from 'app-managers/login.manager';
import { RequestResult } from 'app-models/common/requestResult.model';
import { TranslationService } from 'app-services/translation.service';
import { isEmpty } from 'lodash';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { ImageService } from 'app-services/image.service';
import { Image } from 'app-configurations/images.configuration';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login-page',
  templateUrl: './login.view.html',
  styleUrls: ['./login.style.scss'],
})
export class LoginPageComponent extends ComponentWithSubscriptions implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  app: string;
  error: any;
  displayQR: boolean;
  code: string;
  displayTFA: boolean;
  userTFA: any;
  successMessage: any;
  changePassword: boolean;
  showNewPasswordForm: boolean;
  backgroundImage: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private loginManager: LoginManager,
    private imageService: ImageService,
  ) {
    super();
  }

  ngOnInit() {
    this.backgroundImage = `url(${this.imageService.getImage(Image.loginBackground)})`;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.app = this.route.snapshot.queryParamMap.get('app') || '';
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '';
    this.changePassword = false;
    this.showNewPasswordForm = false;
    this.displayTFA = false;
  }

  get form() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.loginManager.login(
      this.form.username.value,
      this.form.password.value,
      undefined,
      this.app,
      this.returnUrl,
      this.changePassword
    )
      .pipe(first())
      .subscribe(
        (result: RequestResult) => {
          if (!result.response.success) {
            if (result.raw?.status === 206) {
              const errMessage: string = result.response.error;
              if (errMessage?.toLowerCase().includes('not enabled')) {
                this.error = undefined;
                this.loginManager.setup(
                  this.form.username.value,
                  this.form.password.value,
                )
                  .pipe(first())
                  .subscribe(
                    (res: RequestResult) => {
                      if (!res.response.error) {
                        this.userTFA = res.response;
                        this.displayTFA = this.userTFA.secret || this.userTFA.data_url;
                        if (this.userTFA.data_url) {
                          this.displayQR = !this.userTFA.secret;
                        }
                      }
                      else {
                        this.displayTFA = false;
                        this.displayQR = false;
                        this.error = this.translationService.translate(res.response.error || 'error');
                      }
                      this.loading = false;
                    },
                    error => {
                      this.error = error.error;
                      this.loading = false;
                    });
              } else if (errMessage?.toLowerCase().includes('no code')) {
                this.userTFA = undefined;
                this.error = undefined;
                this.displayTFA = true;
                this.displayQR = false;
                this.loading = false;
              }
            }
          } else if (this.changePassword || result.response.changePassword) {
            this.showNewPasswordForm = true;
            this.error = undefined;
          } else if (result.response.url) {
            window.location.replace(result.response.url);
          }
          this.loading = false;
        },
        error => {
          this.error = error.error?.data?.error || 'Unauthorized';
          this.loading = false;
        });
  }

  onCodeSubmit() {
    // tslint:disable-next-line: radix
    const codeNum = Number.parseInt(this.code);
    if (this.code.length !== 6 || codeNum === NaN) {
      return;
    }
    this.loading = true;
    this.error = undefined;

    if (!isEmpty(this.userTFA)) {
      this.loginManager.verify(
        this.form.username.value,
        this.form.password.value,
        this.code,
      )
        .pipe(first())
        .subscribe(
          (result: RequestResult) => {
            if (result.response?.status === 200) {
              this.displayTFA = false;
              this.displayQR = false;
              this.successMessage = result.response.message;
              this.code = '';
            } else {
              this.error = result.response?.message || 'error';
            }
            this.loading = false;
          },
          error => {
            this.error = error.error?.data?.message || error;
            this.loading = false;
          });
    } else {
      this.loginManager.login(
        this.form.username.value,
        this.form.password.value,
        this.code,
        this.app,
        this.returnUrl,
        this.changePassword
      )
        .pipe(first())
        .subscribe(
          (result: RequestResult) => {
            if (result.response.success) {
              if (this.changePassword || result.response.changePassword) {
                this.showNewPasswordForm = true;
                this.error = undefined;
                this.displayTFA = false;
              } else {
                window.location.replace(result.response.url);
              }
            } else {
              this.error = result.response?.error || 'Invalid code.';
            }

            this.loading = false;
          },
          (error) => {
            this.error = error.error?.data?.error || error;
            this.loading = false;
          });
    }
  }

  onChangePasswordClick = () => {
    this.changePassword = true;
    // this.onSubmit();
  }

  cancelChangePassword = () => {
    this.changePassword = false;
    this.displayTFA = false;
    this.showNewPasswordForm = false;
    this.error = undefined;
  }
}
