import { Injectable } from '@angular/core';
import { HttpRequestService } from 'app-services/httpRequest.service';
import { UrlService } from 'app-services/url.service';
import { Observable } from 'rxjs/Observable';
import { RequestResult } from 'app-models/common/requestResult.model';
import { map } from 'rxjs/operators/map';
import * as _ from 'lodash';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CountriesHelper } from 'app-helpers/countries.helper';
import { UserDTO } from 'app-models/user/user-dto.model';
import { LogoHelper } from 'app-helpers/logo.helper';
import { AppPermissionsResult } from 'app-models/permission/app-permissions-result';

@Injectable({
  providedIn: 'root'
})
export class CustomersManager {

  readonly CONTROLLER_NAME = 'customers';

  constructor(
    private httpRequestService: HttpRequestService,
    private urlService: UrlService,
    private countriesHelper: CountriesHelper,
    private logoHelper: LogoHelper,
  ) { }

  getAll = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME);
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: CustomerDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          const customers = response.data;
          customers.forEach(customer => {
            const countryLogoUrl = this.countriesHelper.getFlagPath(customer.country);
            customer.logoUrl = countryLogoUrl;
          });
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  getCustomer = (id: CustomerDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}`);
    return this.httpRequestService.get(url)
      .pipe(
        map((customer: CustomerDTO ) => {
          const requestResult: RequestResult = new RequestResult();
          const countryLogoUrl = this.countriesHelper.getFlagPath(customer.country);
          customer.logoUrl = countryLogoUrl;
          requestResult.response = customer;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  updateCustomerDetails = (id: CustomerDTO['id'], newCustomerDetails: Partial<CustomerDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}`);
    return this.httpRequestService.post(url, {
      newCustomerDetails
    })
      .pipe(
        map((response: {success: boolean}) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  createNewCustomer = (newCustomerDetails: Partial<CustomerDTO>): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME);
    return this.httpRequestService.post(url, {
      newCustomerDetails
    })
      .pipe(
        map((response: { success: boolean }) => {
          const requestResult: RequestResult = new RequestResult();
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw(error);
      });
  }

  changeCustomerStatus = (id: CustomerDTO['id'], status: boolean): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/status`);
    return this.httpRequestService.post(url, {
      status,
    })
    .pipe(
      map((response: any) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  getCustomerAssignedUsers = (id: CustomerDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/users`);
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: UserDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          const users = response.data;
          users.forEach(user => {
            const userLogoUrl = this.logoHelper.generateLogoUrl(`${user.first_name || ''}+${user.last_name || ''}`);
            user.logoUrl = userLogoUrl;
          });
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  getCustomerUnassignedUsers = (id: CustomerDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/unassigned-users`);
    return this.httpRequestService.get(url)
      .pipe(
        map((response: { data: UserDTO[] }) => {
          const requestResult: RequestResult = new RequestResult();
          const users = response.data;
          users.forEach(user => {
            const userLogoUrl = this.logoHelper.generateLogoUrl(`${user.first_name || ''}+${user.last_name || ''}`);
            user.logoUrl = userLogoUrl;
          });
          requestResult.response = response;
          return requestResult;
        }))
      .catch(error => {
        throw (error);
      });
  }

  assignUsersToCustomer = (id: CustomerDTO['id'], usersToAssign: UserDTO['id'][]): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/users`);
    return this.httpRequestService.post(url, {
      usersToAssign,
    })
    .pipe(
      map((response: { success: boolean }) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  unassignUserFromCustomer = (id: number, userId: string | number): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/users/${userId}`);
    return this.httpRequestService.delete(url)
    .pipe(
      map((response: { success: boolean }) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      })
    );
  }

  getPermissions = (id: CustomerDTO['id']): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/permissions`);
    return this.httpRequestService.get(url)
    .pipe(
      map((response: { data: AppPermissionsResult[] }) => {
      const requestResult: RequestResult = new RequestResult();
      requestResult.response = response;
      return requestResult;
      }));
  }

  addPermission = (id: CustomerDTO['id'], permissionId: number, applicationId: number): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/permissions`);
    return this.httpRequestService.post(url, {
      id,
      permissionId,
      applicationId,
    })
    .pipe(
      map((response: {success: boolean}) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  deletePermission = (id: CustomerDTO['id'], permissionId: number, applicationId: number): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, `${id}/permissions/${permissionId}/app/${applicationId}`);
    return this.httpRequestService.delete(url)
    .pipe(
      map((response: {success: boolean}) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

  getExpiryDate = (): Observable<RequestResult> => {
    const url = this.urlService.getRequestUrl(this.CONTROLLER_NAME, 'details/expiry-date');
    return this.httpRequestService.get(url).pipe(
      map((response: {data: string | null }) => {
        const requestResult: RequestResult = new RequestResult();
        requestResult.response = response;
        return requestResult;
      }))
    .catch(error => {
      throw (error);
    });
  }

}
