import { Component, Input, OnInit } from '@angular/core';
import { Icon } from 'app-models/common/icon.model';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CustomerDetailsService } from 'app-views/customer-details/customer-details.service';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.scss']
})
export class CustomerCardComponent implements OnInit {

  @Input() customer: CustomerDTO;
  expiryIcon: Icon;


  constructor(
    private customerDetailsService: CustomerDetailsService,
  ) { }

  ngOnInit(): void {
    this.expiryIcon = undefined;
    if (!!this.customer.expiry_date) {
      this.expiryIcon = this.customerDetailsService.getExpiryIcon(this.customer.expiry_date);
    }
  }
}
