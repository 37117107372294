import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';


// primeNg
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ChipModule } from 'primeng/chip';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';

// layouts
import { MainLayoutComponent } from 'app-layouts/layouts.module';

// pages
import {
    AppComponent,
    LoginPageComponent, LoginPageService,
    UnauthorizedPageComponent
} from 'app-pages/pages.module';
import { UsersPageComponent } from 'app-pages/users/users.page';
import { AppsPageComponent } from 'app-pages/apps/apps-page/apps.page';
import { PermissionsPageComponent } from 'app-pages/permissions-page/permissions.page';
import { CustomersPageComponent } from 'app-pages/customers/customers.page';

// managers
import { LoginManager } from 'app-managers/managers.module';

// components
import { TopBarComponent } from 'app-components/top-bar/top-bar.component';
import { PageNavigatorComponent } from 'app-components/page-navigator/page-navigator.component';
import { UserCardComponent } from 'app-components/user-card/user-card.component';
import { TableComponent } from 'app-components/tables/table/table.component';
import { TableCellComponent } from 'app-components/tables/table-cell/table-cell.component';
import { AddUserComponent } from 'app-components/add-user/add-user.component';
import { AppCardComponent } from 'app-components/app-card/app-card.component';
import { AddAppComponent } from 'app-components/add-app/add-app.component';
import { AddCustomerComponent } from 'app-components/add-customer/add-customer.component';
import { CustomerCardComponent } from 'app-components/customer-card/customer-card.component';
import { SearchInputComponent } from 'app-components/inputs/search/search.component';
import { UserActionComponent } from 'app-components/buttons/user-action/user-action.component';
import { ChangePasswordComponent } from 'app-components/change-password/change-password.component';
import { MagenSpinnerComponent } from 'app-components/magen-spinner/magen-spinner.component';


// views
import { UserDetailsComponent } from 'app-views/user-details/user-details.component';
import { AppDetailsComponent } from 'app-views/app-details/app-details.component';
import { CustomerDetailsComponent } from 'app-views/customer-details/customer-details.component';

// global services
import {
    ApplicationGlobalsService,
    UrlService,
    UserService,
    PageService,
    HttpRequestService,
    LocalizationService,
    TranslationService,
    ImageService
} from 'app-services/services.module';

import { ApplicationLoadService } from 'app-services/applicationLoad.service';

// directives
import { AutofocusDirective } from 'app-directives/autofocus.directive';
import { AllowedRolesDirective } from 'app-directives/allowed-roles.directive';
import { AllowedPermissionsDirective } from 'app-directives/allowed-permissions.directive';

// pipes
import {
    TranslatePipe,
    AddCommasPipe,
    SafeResourceUrl,
} from 'app-pipes/pipes.module';

// helpers
import { CountriesHelper } from 'app-helpers/countries.helper';



require('./externals');
export function initApplication(appLoadService: ApplicationLoadService) {
    return () => appLoadService.initApplication();
}

@NgModule({
    declarations: [
        MainLayoutComponent,

        // pages
        AppComponent,
        LoginPageComponent,
        UnauthorizedPageComponent,
        UsersPageComponent,
        AppsPageComponent,
        PermissionsPageComponent,
        CustomersPageComponent,

        // components
        TopBarComponent,
        PageNavigatorComponent,
        UserCardComponent,
        AddUserComponent,
        TableComponent,
        TableCellComponent,
        AppCardComponent,
        AddAppComponent,
        SearchInputComponent,
        UserActionComponent,
        ChangePasswordComponent,
        MagenSpinnerComponent,

        // views
        UserDetailsComponent,
        AppDetailsComponent,
        CustomerDetailsComponent,


        // directives
        AutofocusDirective,
        AllowedRolesDirective,
        AllowedPermissionsDirective,

        // pipes
        TranslatePipe,
        AddCommasPipe,
        SafeResourceUrl,
        CustomerCardComponent,
        AddCustomerComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        // primeNG
        InputTextModule,
        ButtonModule,
        DropdownModule,
        MultiSelectModule,
        CheckboxModule,
        CardModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        ToastModule,
        TableModule,
        InputSwitchModule,
        TooltipModule,
        ChipModule,
        PasswordModule,
        CalendarModule,
    ],
    providers: [
        // components

        // pages
        LoginPageService,

        // global services
        ApplicationGlobalsService,
        UrlService,
        UserService,
        PageService,
        HttpRequestService,
        LocalizationService,
        TranslationService,
        ImageService,
        ApplicationLoadService,
        { provide: APP_INITIALIZER, useFactory: initApplication, deps: [ApplicationLoadService], multi: true },

        // managers
        LoginManager,

        // helpers
        CountriesHelper,

        // primeNG
        ConfirmationService,
        MessageService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
