import { Injectable } from '@angular/core';
import { ApplicationGlobalsService } from 'app-services/applicationGlobals.service';
import { User, UserLanguage } from 'app-models/user/user.module';
import { Roles } from 'app-models/roles/roles.enum';
import { getArray } from 'app-scripts/utilities/general';
import { UsersManager } from 'app-managers/users.manager';
import { intersection, isEmpty, isUndefined } from 'lodash';
import { UserPermissionEnum } from 'app-models/user/permission.enum';

@Injectable()
export class UserService {
    constructor(
        private applicationGlobalsService: ApplicationGlobalsService,
        private usersManager: UsersManager,
    ) { }

    private get _globalUser() {
      return this.applicationGlobalsService.user;
    }

    set(userInfo: any): void {
        const user = new User();
        user.language = userInfo?.language || 'en';
        user.role = userInfo?.role;
        user.permissions = userInfo.permissions;
        this.applicationGlobalsService.user = user;
    }

    set language(language: UserLanguage) {
        this._globalUser.language = language;
        this.applicationGlobalsService.user = this._globalUser;
    }

    get language(): UserLanguage {
        return this._globalUser === undefined ? UserLanguage.English : this._globalUser.language;
    }

    isAdmin = (): boolean => {
      const role = this._globalUser.role;
      return role.name === 'admin';
    }

    hasRole = (role: Roles | Roles[]): boolean => {
      if (isUndefined(role)) { return true; }
      const userRole = this._globalUser?.role.name;
      if (!userRole) { return false }
      const roles = getArray(role);
      const allowed = this.isAdmin() || roles.includes(userRole);
      return allowed;
    }

    hasPermission = (permission: UserPermissionEnum | UserPermissionEnum[]): boolean => {
      if (isUndefined(permission)) { return true; }
      const permissions = getArray(permission);
      return !isEmpty(intersection(permissions, this._globalUser?.permissions));
    }

    logout = () => {
      this.usersManager.logout();
    }

}
