import { AfterViewInit, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Roles } from 'app-models/roles/roles.enum';
import { UserService } from 'app-services/user.service';
import { isEmpty, isNull, toArray } from 'lodash';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[allowedRoles]'
})
export class AllowedRolesDirective implements OnInit, AfterViewInit {
  private _allowedRoles: Roles[];
  @Input() set allowedRoles(roles: Roles[]) {
    this._allowedRoles = roles;
  }

  private _onlyAdmins: boolean;
  @Input() set allowedRolesOnlyAdmins(value: boolean) {
    this._onlyAdmins = value;
  }

  private _disabled: boolean;
  @Input() set allowedRolesDisabled(value: boolean) {
    this._disabled = value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    const allowed = this.isAllowed();
    if (!allowed && !this._disabled) {
      // Remove element from DOM
      this.viewContainerRef.clear();
    } else {
      // appends the ref element to DOM
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  public ngAfterViewInit(): void {
    const allowed = this.isAllowed();
    if (!allowed && this._disabled) {
      const siblingElement = this.templateRef.elementRef.nativeElement.previousElementSibling;
      if (!isNull(siblingElement)) {
        siblingElement.disabled = true;
        siblingElement.style.cursor = 'unset';
        const children = siblingElement.getElementsByTagName('*');
        toArray(children).forEach((child) => {
          child.style.cursor = 'unset';
          child.disabled = true;
        });
      } else {
        console.log(this.templateRef.elementRef.nativeElement);
      }
      this.handleClickEvents();
    }
  }

  private isAllowed = (): boolean => {
    let allowed: boolean;
    if (this._onlyAdmins) {
      allowed = this.userService.isAdmin();
    } else {
      allowed = isEmpty(this._allowedRoles) || this.userService.hasRole(this._allowedRoles);
    }
    return allowed;
  }

  private handleClickEvents() {
    const parentNode = this.templateRef.elementRef.nativeElement.previousElementSibling;
    if (!isNull(parentNode)) {
      parentNode.addEventListener('click', (event) => {
        event.stopPropagation();
      }, { capture: true });
    }
  }
}
