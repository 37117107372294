import { Component } from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'main-layout',
    templateUrl: 'layout.view.html',
    styleUrls: ['./layout.style.scss']
})

export class MainLayoutComponent {
    constructor() { }
}

