import { Injectable } from '@angular/core';
import { LocalizationConfiguration } from 'app-configurations/localization.configuration';
import { UserService } from './user.service';
import { ApplicationGlobalsService } from './applicationGlobals.service';
import * as _ from 'lodash';

@Injectable()
export class LocalizationService {
    constructor(private userService: UserService, private applicationGlobalsService: ApplicationGlobalsService) { }

    // base language is english
    get baseLocalizationFile(): any {
        return LocalizationConfiguration['en'];
    }

    get localizationFile(): any {
        const lang = this.userService.language;
        return LocalizationConfiguration[lang];
    }

    getLanguageValue = (key: string): any => {
        const lang = this.applicationGlobalsService.localization;
        return _.get(lang, key);
    }

    getBaseLanguageValue = (key: string): any => {
        const lang = this.applicationGlobalsService.baseLocalization;
        return lang[key];
    }
}
