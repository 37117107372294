import { Injectable } from '@angular/core';
import { PageNavigatorConfigurations } from 'app-components/page-navigator/page-navigator.configurations';
import { UserService } from 'app-services/user.service';
import { orderBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {
  constructor(
    private userService: UserService,
  ) { }

  getRoutes = () => {
    let routes = orderBy(Object.values(PageNavigatorConfigurations.routes), ['order'], ['asc']);
    routes = routes.filter((route) => this.userService.hasPermission(route.permissions) &&  this.userService.hasRole(route.roles) );
    return routes;
  }

}
