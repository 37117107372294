import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { UserDTO } from 'app-models/user/user-dto.model';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RolesService } from 'app-services/roles.service';
import { Roles } from 'app-models/roles/roles.enum';
import { UserService } from 'app-services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent extends ComponentWithSubscriptions implements OnInit {

  readonly Roles = Roles;

  detailsForm: FormGroup;
  roles: any;
  adminRole: {id: number, name: Roles };
  fetchInProgress: boolean;

  constructor(
    private rolesService: RolesService,
    public ref: DynamicDialogRef,
    private userService: UserService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchInProgress = true;
    this.detailsForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      role: new FormControl('', Validators.required),
    });
    this.subscriptions.push(this.rolesService.getAll().subscribe((res) => {
      if (res) {
        this.roles = res.map((role) => {
          return {
            id: role.id,
            name: role.name.upperCaseFirstLetter(),
          }
        });
        this.adminRole = this.roles.find((role) => role.name.toLowerCase() === Roles.Admin);
        this.detailsForm.controls['role'].setValue(this.adminRole);
        this.fetchInProgress = false;
      }
    }));

  }

  saveUser = () => {
    const newUserDetails: Partial<UserDTO> = this.detailsForm.value;
    if (!this.userService.hasRole([Roles.Admin, Roles.Opeartor])) {
      newUserDetails.role = this.roles.find((role) => role.name.toLowerCase() === Roles.Manager);
    }
    if (newUserDetails.role?.name) {
      newUserDetails.role.name = newUserDetails.role.name.toLowerCase() as Roles;
    }
    this.ref.close(newUserDetails);
  }
}
