import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerDTO } from 'app-models/customer/customer-dto.model';
import { CountriesHelper } from 'app-helpers/countries.helper';
import { Country } from 'app-models/common/country.model';
import moment from 'moment';
import { Roles } from 'app-models/roles/roles.enum';
import { UserService } from 'app-services/user.service';
import { CustomersService } from 'app-pages/customers/customers.service';
import { isNil } from 'lodash';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent extends ComponentWithSubscriptions implements OnInit {
  readonly Roles = Roles;
  detailsForm: FormGroup;
  countries: Country[];
  country: string;
  todayDate: Date;

  constructor(
    private countriesHelper: CountriesHelper,
    public ref: DynamicDialogRef,
    private userService: UserService,
    private customerService: CustomersService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.todayDate = new Date();
    this.countries = this.countriesHelper.getCountries();
    this.detailsForm = new FormGroup({
      name: new FormControl('', Validators.required),
      country: new FormControl(null, Validators.required),
      expiryDate: new FormControl(null),
    });
  }

  onCountryChange = (country: string) => {
    this.country = country;
  }

  saveCustomer = () => {
    let expiryDate;
    if (!this.userService.hasRole([Roles.Admin, Roles.Opeartor])) {
      this.saveWithParentExpiryDate();
    } else {
      expiryDate = moment(this.detailsForm.value['expiryDate']).utc(true).endOf('day');
      const newCustomerDetails: Partial<CustomerDTO> = {
        name: this.detailsForm.value['name'],
        country: this.detailsForm.value['country'],
        expiry_date: expiryDate.valueOf()
      }
      this.ref.close(newCustomerDetails);
    }
  }

  saveWithParentExpiryDate = () => {
    this.subscriptions.push(this.customerService.getExpiryDate().subscribe((res) => {
      if (res) {
        const expiry_date = !isNil(res.data) ? parseInt(res.data) : res.data;
        const newCustomerDetails: Partial<CustomerDTO> = {
          name: this.detailsForm.value['name'],
          country: this.detailsForm.value['country'],
          expiry_date
        }
        this.ref.close(newCustomerDetails);
      }
    }));
  }
}
