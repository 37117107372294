import { Injectable } from '@angular/core';
import { ApplicationGlobalsService } from 'app-services/applicationGlobals.service';


@Injectable()
export class UrlService {
    constructor(
        private applicationGlobalsService: ApplicationGlobalsService
    ) { }

    getRequestUrl(controllerName: string, methodName?: string): any {
        let result = '';
        const baseApi = this.applicationGlobalsService.globalConfigurations.apiUrl;
        if (methodName) {
            result = `${baseApi}${controllerName}/${methodName}`;
        } else {
            result = `${baseApi}${controllerName}`;
        }

        return result;
    }

    hasParams(): boolean {
        return window.document.URL.toString().split('?').length > 1;
    }

    get queryParams(): any {
        const retunParams: any[] = [];
        if (this.hasParams()) {
            const params = window.document.URL.toString().split('?')[1].replace(/%2C/g, ',')
                .split('&')
                .map(v => v.split('='));
            params.forEach((param: any) => {
                retunParams.push(param);
            });
        }

        return retunParams;
    }


    getUrlParam(name): any {
        const params = this.queryParams;
        const result = {};
        for (let index = 0; index < params.length; index++) {
            const param = params[index];
            result[param[0]] = param[1];
        }
        return result['name'];
    }
}
