import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserDetailsService } from 'app-views/user-details/user-details.service';
import { ToastMessagesConfiguration } from 'app-configurations/toast-messages.cofiguration';
import { ComponentWithSubscriptions } from 'app-models/components/component-with-subscriptions.class';
import { TranslationService } from 'app-services/translation.service';
import { Severity } from 'app-models/toast/severity.enum';
import { Text } from 'app-models/common/text.model';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends ComponentWithSubscriptions implements OnInit {

  passwordForm: FormGroup;
  showPassword: boolean;
  eightChars: boolean;
  upperCase: boolean;
  lowerCase: boolean;
  containsNumber: boolean;
  specialChar: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userDetailsService: UserDetailsService,
    private translation: TranslationService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.showPassword = this.upperCase = this.lowerCase = this.containsNumber = this.specialChar = false;
    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, this.checkPasswordStrength]],
      confirmPassword: [''],
    }, { validators: [this.checkNewPasswordsMatch] });

    this.subscriptions.push(this.passwordForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(newValue => {
        if (newValue.newPassword.length >= 8) {
          this.eightChars = true;
        } else {
          this.eightChars = false;
        }
        if (/[A-Z]/.test(newValue.newPassword)) {
          this.upperCase = true;
        } else {
          this.upperCase = false;
        }
        if (/[a-z]/.test(newValue.newPassword)) {
          this.lowerCase = true;
        } else {
          this.lowerCase = false;
        }
        if (/[0-9]/.test(newValue.newPassword)) {
          this.containsNumber = true;
        } else {
          this.containsNumber = false;
        }
        if (/[^A-Za-z0-9]/.test(newValue.newPassword)) {
          this.specialChar = true;
        } else {
          this.specialChar = false;
        }
      }));
  }

  changeShowPasswordStatus(): void {
    this.showPassword = !this.showPassword;
  }

  checkNewPasswordsMatch: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    const pass = group.get('newPassword').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  checkPasswordStrength: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const pass = control.value;
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    return strongPassword.test(pass) ? null : { weakPassword: true };
  }

  savePassword = () => {
    const newPassword = this.passwordForm.controls['newPassword'].value;
    this.changePassword(newPassword);
  }

  changePassword = (newPassword: string) => {
    if (isEmpty(newPassword)) { return; }
    this.subscriptions.push(this.userDetailsService.changePassword(newPassword).subscribe((res) => {
      if (res?.success) {
        // this.addToast(
        //   ToastMessagesConfiguration.PASSWORD_CHANGED, Severity.Success);
          window.location.reload();
      } else {
        this.addToast(new Text(res.message, false), Severity.Error);
        this.passwordForm.get('newPassword').reset();
        this.passwordForm.get('confirmPassword').reset();
      }
    }, (err) => {
      this.addToast(ToastMessagesConfiguration.PASSWORD_CHANGED_ERROR, Severity.Error);
    }));
  }

  addToast(message: Text, severity: string) {
    const detail = message.needsTranslation
      ? this.translation.translate(message.text)
      : message.text;
    this.messageService.add({
      severity,
      summary: this.translation.translate(severity),
      detail,
      key: 'toastMessage',
    });
  }

}
