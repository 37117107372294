import { GenericObject } from 'app-models/common/generic-object';
import { ComponentsNamesPerRouteEnum } from 'app-models/page/componentsNamesPerRouteEnum';
import { PageNavigationItem } from 'app-models/page/page-navigation-item.model';
import { Roles } from 'app-models/roles/roles.enum';
import { UserPermissionEnum } from 'app-models/user/permission.enum';

export const PageNavigatorConfigurations: { routes: GenericObject<PageNavigationItem>} = {
  routes: {
    [ComponentsNamesPerRouteEnum.Customers]: {
      order: 0,
      route: '/customers',
      text: 'page_navigator_titles.customers',
      permissions: [UserPermissionEnum.Customers]
    },
    [ComponentsNamesPerRouteEnum.Users]: {
      order: 1,
      route: '/users',
      text: 'page_navigator_titles.users',
      permissions: [UserPermissionEnum.Users]
    },
    [ComponentsNamesPerRouteEnum.Permissions]: {
      order: 2,
      route: '/permissions',
      text: 'page_navigator_titles.permissions',
      permissions: [UserPermissionEnum.Permissions]
    },
    [ComponentsNamesPerRouteEnum.Apps]: {
      order: 3,
      route: '/apps',
      text: 'page_navigator_titles.apps',
      permissions: [UserPermissionEnum.Apps]
    }
  }
};
